import React from 'react';
import {css} from 'emotion';
import Toggle from '@amzn/meridian/toggle';
import DateRangePicker from '@amzn/meridian/date-range-picker';
import Row from '@amzn/meridian/row';
import SiteFilter, {SiteData} from '../../common/components/SiteFilter';

const rowSpacing = css`
  padding-top: 0.5%;
  padding-bottom: 0.5%;
`;

const smallMargin = css`
  margin-bottom: 4px !important;
`;

interface IProps {
  isWidescreen: boolean;
  endDate: string;
  filterValue: string[];
  maxDate: string;
  minDate: string;
  setEndDateValue: React.Dispatch<React.SetStateAction<string>>;
  setFilter: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  setShowDataLabels: React.Dispatch<React.SetStateAction<boolean>>;
  setStartDateValue: React.Dispatch<React.SetStateAction<string>>;
  showDataLabels: boolean;
  siteData: SiteData;
  startDate: string;
};

const DataFilter = ({
  isWidescreen,
  endDate,
  filterValue,
  maxDate,
  minDate,
  setEndDateValue,
  setFilter,
  setShowDataLabels,
  setStartDateValue,
  showDataLabels,
  siteData,
  startDate,
}: IProps) => {
  const setDateRange = (value: string[] | undefined) => {
    setStartDateValue((value && value[0]) || '');
    setEndDateValue((value && value[1]) || '');
  };

  const isDateDisabled = (value: string): boolean => {
    if (!minDate || !maxDate) { return false; }
    return value < minDate || value > maxDate;
  };

  const datePicker = (
    <DateRangePicker
      width={500}
      disabledDates={isDateDisabled}
      value={[startDate, endDate]}
      onChange={setDateRange}
      startLabel="Start Date"
      endLabel="End Date"
      monthsInView={2}
    />);

  const dataLabelPicker = (
    <Toggle checked={showDataLabels} onChange={setShowDataLabels}>
      Show Data Labels
    </Toggle>);

  return (
    <>
      <SiteFilter
        isWidescreen={isWidescreen}
        filterValue={filterValue}
        setFilter={setFilter}
        siteData={siteData}
      />
      { isWidescreen ? (
        <Row className={rowSpacing}>
          {datePicker}
          {dataLabelPicker}
        </Row>
      ) : (
        <div className={rowSpacing}>
          <Row className={smallMargin}>
            {datePicker}
          </Row>
          <Row className={smallMargin}>
            {dataLabelPicker}
          </Row>
        </div>
      )}
    </>
  );
};

export default DataFilter;
