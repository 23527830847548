import React from 'react';
import {NavLink} from 'react-router-dom';
import {ITheme} from '../../../common/styles/themes/models';

import { getLinkNotSelected, getLinkSelected } from './LinkStyles';

interface IProps {
  theme: ITheme;
};

const AdminLink = ({theme}: IProps) => {
  return (
    <NavLink exact to="/admin" className={getLinkNotSelected(theme)} activeClassName={getLinkSelected(theme)}>
      Admin
    </NavLink>
  );
};

export default AdminLink;
