import React from 'react';
import ReactDOM from 'react-dom';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {store} from './app/store';
import ToplineApp from './app/ToplineApp';
import AuthProvider from './app/AuthProvider';
import ForecastProvider from './app/ForecastProvider';


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <ForecastProvider>
          <ToplineApp />
        </ForecastProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
