import React from 'react';
import {formatValue} from './formatting';

interface CustomAxisProps {
  x?: number;
  y?: number;
  stroke?: string;
  payload?: any;
};

const LargeNumberAxis = ({x, y, payload}: CustomAxisProps) => {
  return (
    /* @ts-ignore */
    <text x={x} y={y} dx={-8} dy={5} textAnchor="end" fill="#666">
      {formatValue(payload.value)}
    { /* @ts-ignore */ }
    </text>
  );
};

export default LargeNumberAxis;