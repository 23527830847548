import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

interface ICachedState {
  business: string | null;
  region: number | null;
  country: string | null;
  userSelection?: string;
}

const buildUserSelection = (business: string | null, country: string | null) => {
  let userSelectionBuilder = '';
  if (business && business !== 'undefined') userSelectionBuilder += business;
  if (country && country !== 'undefined') userSelectionBuilder += '-' + country;

  return userSelectionBuilder;
};

const userStorage = window.localStorage;

const initialState: ICachedState = {
  business: userStorage.getItem('business'),
  region: Number(userStorage.getItem('region')),
  country: userStorage.getItem('country'),
  userSelection: buildUserSelection(userStorage.getItem('business'), userStorage.getItem('country')),
};

const cachedStateSlice = createSlice({
  name: 'cachedState',
  initialState,
  reducers: {
    setCachedState: (state, action: PayloadAction<ICachedState>) => {
      state.business = action.payload.business;
      state.region = action.payload.region;
      state.country = action.payload.country;
      state.userSelection = buildUserSelection(state.business, state.country);
    },
  },
});

export const {setCachedState} = cachedStateSlice.actions;

export const selectCachedBusiness = (state: RootState) => state.cachedState.business;
export const selectCachedRegion = (state: RootState) => state.cachedState.region;
export const selectCachedCountry = (state: RootState) => state.cachedState.country;
export const selectCachedUserSelection = (state: RootState) => state.cachedState.userSelection;

export default cachedStateSlice.reducer;
