import React from 'react';
import {css} from 'emotion';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import {ForecastRecord} from '../../common/apis/models/forecast';
import {formatForecastTitle} from './utils';

export interface IProps {
  forecast: ForecastRecord;
};

const forecastTitleStyle = css`
  margin-bottom: 0 !important;
`;

const ForecastTitle = ({forecast}: IProps) => {
  return (
    <>
      <Row>
        <Text>You are currently viewing the following forecast:</Text>
      </Row>
      <Row className={forecastTitleStyle}>
        <Text type="h200">{formatForecastTitle(forecast)}</Text>
      </Row>
      <Row>
        <Text type="h100">{`Version ${forecast.versionId}`}</Text>
      </Row>
    </>
  );
};

export default ForecastTitle;
