import {ForecastRecord} from './forecast';

export enum ForecastListSortKeys {
  ForecastId = 'forecastId',
  VersionId = 'versionId',
  RegionId = 'regionId',
  BusinessId = 'businessId',
  Country = 'country',
  Period = 'period',
  PeriodStart = 'periodStart',
  Model = 'model',
  Description = 'description',
  Type = 'type',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
}

export interface GetForecastListResponse {
  forecasts: Array<ForecastRecord>;
  pageSize: number;
  totalRecords: number;
}
