import React, {useCallback, useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import Column from '@amzn/meridian/column';
import {css} from 'emotion';
import {GetForecastOverlayListResponse} from '../../common/apis/models/getForecastOverlayListResponse';
import {extractSitesFromForecast, SiteData} from '../../common/components/SiteFilter';
import PageLoading from '../../common/components/PageLoading';
import {IPageProps} from '../../common/page/models';
import ToplinePage from '../../common/page/ToplinePage';
import {WIDESCREEN_QUERY} from '../../common/utils/layout';
import OverlayHeader from './components/OverlayHeader';
import OverlayListTable from './components/OverlayListTable';
import {NETWORK_SITE_ID, OverlayMetricLevel} from './models';

const mastheadAndColumnSpacing = css`
  padding: 0.7% 1.5%;
`;

interface IOverlayPageProps extends IPageProps {
  metricId: string;
  metricLevel: OverlayMetricLevel;
  metricName: string;
};

const OverlayPage = ({businessId, country, forecast, forecastId, forecastState, metricId, metricLevel, metricName, versionId}: IOverlayPageProps) => {
  const hasIdentifiers = Boolean(businessId && country && forecastId && versionId && metricId)
  const isNetworkLevel = metricLevel === OverlayMetricLevel.NETWORK_LEVEL;
  const latestForecastVersionId = forecast?.versions?.forecast[0]?.versionId || 0;
  const latestOverlayVersionId = forecast?.versions?.overlay[metricId][0]?.versionId || 0;
  const hasNewerOverlays = Boolean(forecast?.versions && (latestForecastVersionId === versionId && latestOverlayVersionId  > latestForecastVersionId));
  const [useForecastOverlayVersionId, setUseForecastOverlayVersionId] = useState(false);
  const [overlayList, setOverlayList] = useState<GetForecastOverlayListResponse | void>();
  const isWidescreen = useMediaQuery(WIDESCREEN_QUERY);
  const [siteData, setSiteData] = useState<SiteData>({
    siteIdentifiers: new Set<string>(),
    siteNames: new Set<string>(),
    siteMapping: {} as {[siteName: string]: Set<string>},
  });
  const [shouldUpdateOverlayList, setShouldUpdateOverlayList] = useState(true);
  const [siteIdentifiers, setSites] = useState<string[] | undefined>();
  const overlayVersionId = (useForecastOverlayVersionId || !hasNewerOverlays) ? versionId! : (latestOverlayVersionId || versionId!);

  const onOverlayUploadSuccess = useCallback(() => {
    setShouldUpdateOverlayList(true);
  }, [setShouldUpdateOverlayList]);

  useEffect(() => {
    const extractedSites = isNetworkLevel ? {
      siteIdentifiers: new Set<string>([NETWORK_SITE_ID]),
      siteNames: new Set<string>([NETWORK_SITE_ID]),
      siteMapping: {[NETWORK_SITE_ID]: new Set<string>([NETWORK_SITE_ID])},
    } : extractSitesFromForecast(forecast);
    setSiteData(extractedSites);
    setSites(Array.from(extractedSites.siteIdentifiers));
  }, [forecast, isNetworkLevel]);

  return (
    <Column className={mastheadAndColumnSpacing}>
      {hasIdentifiers ? (
        <>
          <OverlayHeader
            businessId={businessId!}
            country={country!}
            filterValue={siteIdentifiers || [] as string[]}
            forecastId={forecastId!}
            forecastState={forecastState!}
            hasNewerOverlays={hasNewerOverlays}
            isNetworkLevel={isNetworkLevel}
            isWidescreen={isWidescreen}
            onOverlayUploadSuccess={onOverlayUploadSuccess}
            overlayMetric={metricId}
            siteData={siteData}
            setFilter={setSites}
            setUseForecastOverlayVersionId={setUseForecastOverlayVersionId}
            useForecastOverlayVersionId={useForecastOverlayVersionId}
          />
          <OverlayListTable
            businessId={businessId!}
            country={country!}
            forecastId={forecastId!}
            isNetworkLevel={isNetworkLevel}
            overlayList={overlayList}
            overlayMetric={metricId}
            overlayMetricTitle={metricName}
            setOverlayList={setOverlayList}
            setShouldUpdateOverlayList={setShouldUpdateOverlayList}
            shouldUpdateOverlayList={shouldUpdateOverlayList}
            siteIdentifiers={siteIdentifiers}
            versionId={overlayVersionId}
          />
        </>
      ) : (
        <PageLoading />
      )}
    </Column>
  );
};

export default (props: IOverlayPageProps) => (
  <ToplinePage title={`${props.metricName} Overlays`} {...props}>
    <OverlayPage {...props} />
  </ToplinePage>
);
