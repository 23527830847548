import {configureStore} from '@reduxjs/toolkit';
import cachedStateReducer from './slices/cachedStateSlice';
import themeReducer from './slices/themeSlice';
import businessReducer from './slices/businessSlice';
import forecastReducer from './slices/forecastSlice';

export const store = configureStore({
  reducer: {
    cachedState: cachedStateReducer,
    businessState: businessReducer,
    themeState: themeReducer,
    forecastState: forecastReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
