import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import {ForecastRecord} from '../models/forecast';
import {handleError} from './handleError';

export interface PostForecastCloneParams {
  businessId: string;
  country: string;
  forecastId: string;
  versionId: number;
  description: string | null;
}

export const postForecastCloneEndpoint = async (
  forecastingApiGatewayClient: ApiGatewayClient,
  params: PostForecastCloneParams
): Promise<ForecastRecord | void> => {
  try {
    const {businessId, country, forecastId, versionId, description} = params;
    const queryParams = { versionId: versionId.toString(), description };
    return (
      await forecastingApiGatewayClient.invokeApi(
        {businessId, country, forecastId},
        '/business/{businessId}/country/{country}/forecast/{forecastId}/clone',
        'POST',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGatewayErrorResponse);
  }
};
