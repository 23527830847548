import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import {ForecastRecord} from '../models/forecast';
import {handleError} from './handleError';

export interface PostForecastApproveParams {
  businessId: string;
  country: string;
  forecastId: string;
  versionId: number;
}

export const postForecastApproveEndpoint = async (
  forecastingApiGatewayClient: ApiGatewayClient,
  params: PostForecastApproveParams
): Promise<ForecastRecord | void> => {
  try {
    const {businessId, country, forecastId, versionId} = params;
    const queryParams = { versionId: versionId.toString() };
    return (
      await forecastingApiGatewayClient.invokeApi(
        {businessId, country, forecastId},
        '/business/{businessId}/country/{country}/forecast/{forecastId}/approve',
        'POST',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGatewayErrorResponse);
  }
};
