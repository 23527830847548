import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

const COUNTRY_REGION_MAP: {[country: string]: number} = {
  DE: 2,
  ES: 2,
  FR: 2,
  GB: 2,
  IT: 2,
  US: 1,
};

export interface IBusinessState {
  business: string | undefined;
  region: number | undefined;
  country: {
    countrySelection: string | undefined;
    countryVisible: boolean;
    countryValidChoices: string[];
  };
  canEnterPortal: boolean;
}

const initialState: IBusinessState = {
  business: undefined,
  region: undefined,
  country: {
    countrySelection: undefined,
    countryVisible: false,
    countryValidChoices: [],
  },
  canEnterPortal: false,
};

const businessSlice = createSlice({
  name: 'businessSlice',
  initialState,
  reducers: {
    setBusinessAFS: (state) => {
      state.business = 'AFS';
      state.region = undefined;
      state.country = {
        countrySelection: undefined,
        countryVisible: true,
        countryValidChoices: ['US'],
      };
      state.canEnterPortal = false;
    },
    setBusinessUFF: (state) => {
      state.business = 'UFF';
      state.region = undefined;
      state.country = {
        countrySelection: undefined,
        countryVisible: true,
        countryValidChoices: Object.keys(COUNTRY_REGION_MAP),
      };
      state.canEnterPortal = false;
    },
    setCountry: (state, action: PayloadAction<string>) => {
      state.region = COUNTRY_REGION_MAP[action.payload];
      state.country.countrySelection = action.payload;
      state.canEnterPortal = true;
    },
  },
});

export const {
  setBusinessAFS,
  setBusinessUFF,
  setCountry,
} = businessSlice.actions;

export const selectBusinessSelector = (state: RootState) => state.businessState.business;
export const selectRegionSelector = (state: RootState) => state.businessState.region;
export const selectCountrySelector = (state: RootState) => state.businessState.country;
export const selectCanEnterPortalSelector = (state: RootState) => state.businessState.canEnterPortal;

export default businessSlice.reducer;
