export enum SystemAPIs {
  ForecastingApi = 'ForecastingApi',
}

const config = {
  defaults: {
    namespace: 'Application',
  },
  Amplify: {
    Auth: {
      region: 'us-east-1',
      identityPoolId: 'us-east-1:9c86e2c3-00b3-46f8-8f62-0b5bcc325339',
      userPoolId: 'us-east-1_Xg1sps6Z6',
      userPoolWebClientId: 'jqnqvhnkvhiopuojdilf5bl37',
      mandatorySignIn: true,
      oauth: {
        domain: 'alpha-topline-f3.auth.us-east-1.amazoncognito.com',
        scope: ['openid', 'email', 'profile'],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code',
      },
    },
    API: {
      endpoints: [
        {
          name: SystemAPIs.ForecastingApi.toString(),
          endpoint: 'https://er5ilbuyc3.execute-api.us-east-1.amazonaws.com/alpha',
          region: 'us-east-1',
        },
      ],
    },
  },
};

export default config;
