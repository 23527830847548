const BILLION = 1000000000;
const MILLION = 1000000;
const THOUSAND = 1000;
const HUNDRED = 100;
const TEN = 10;

export const formatNumber = (value: string | number | undefined): string | undefined => {
  if (value === undefined) { return value; }
  let numVal = Number(value);
  if (isNaN(numVal)) { return value.toString(); }
  return value.toLocaleString();
}

export const formatValue = (value: string | undefined): string | undefined => {
  if (value === undefined) { return value; }
  let numVal = Number(value);
  if (isNaN(numVal)) { return value; }
  let suffix = '';

  // Convert numbers to suffixes
  if (numVal >= BILLION) {
     numVal /= BILLION;
     suffix = 'B'
  } else if (numVal >= MILLION) {
    numVal /= MILLION;
    suffix = 'M'
  } else if (numVal >= THOUSAND) {
    numVal /= THOUSAND;
    suffix = 'k'
  }

  // Vary precision to limit to 3 numeric characters
  if (numVal > 10) {
    if (numVal > 100) {
      numVal = Math.round(numVal);
    }
    else {
      numVal = Math.round(numVal * TEN) / TEN;
    }
  } else {
    numVal = Math.round(numVal * HUNDRED) / HUNDRED;
  }

  return `${formatNumber(numVal)}${suffix}`;
};