import React, {useEffect, useState} from 'react';
import {css} from 'emotion';
import Column from '@amzn/meridian/column';
import {useAppSelector} from '../../app/hooks';
import {selectTheme} from '../../app/slices/themeSlice';
import {IPageProps} from '../../common/page/models';
import ToplinePage from '../../common/page/ToplinePage';
import {ITheme} from '../../common/styles/themes/models';
import {getForecastGraph, postRestoreForecast} from '../../common/apis/forecasting-api-client';
import {ForecastGraph} from '../../common/apis/models/getForecastGraphResponse';
import ForecastD3Graph from './components/ForecastD3Graph';

const mastheadAndColumnSpacing = css`
  height: 100%;
  padding: 0.7% 1.5%;
`;

const navigateToForecast = ({forecastId, versionId}: {forecastId: string, versionId: number}) => {
  window.location.href = `/forecasts/${forecastId}/versions/${versionId}/summary`;
};

const HistoryPage = ({businessId, country, forecastId, versionId}: IPageProps) => {
  const theme = useAppSelector(selectTheme) as ITheme;
  const [forecastGraph, setForecastGraph] = useState<ForecastGraph>();
  const [isLoading, setIsLoading] = useState(true);
  const hasIdentifiers = Boolean(businessId && country && forecastId);

  useEffect(() => {
    if (!hasIdentifiers) { return; }
    getForecastGraph({
      businessId: businessId!,
      country: country!,
      forecastId: forecastId!,
    }).then(graph => {
      setForecastGraph(graph || undefined);
      setIsLoading(false);
    });
  }, [hasIdentifiers, businessId, country, forecastId]);

  return (
    <Column className={mastheadAndColumnSpacing}>
      <ForecastD3Graph
        forecastGraph={forecastGraph || null}
        forecastId={forecastId || null}
        versionId={versionId || null}
        isLoading={isLoading}
        onRestoreDiscardedForecast={(forecastData) => {
          setIsLoading(true);
          postRestoreForecast({
            businessId: businessId!,
            country: country!,
            forecastId: forecastData.forecastId,
            versionId: forecastData.versionId,
          }).then((restoredForecast) => {
            setIsLoading(false);
            if (!restoredForecast) {
              return void console.error('ERROR restoring forecast');
            }
            navigateToForecast(restoredForecast);
          });
        }}
        onSelectForecast={(forecastData) => {
          navigateToForecast(forecastData);
        }}
        theme={theme}
      />
    </Column>
  );
};

export default (props: IPageProps) => (
  <ToplinePage title="History" {...props}>
    <HistoryPage {...props} />
  </ToplinePage>
);
