import React from 'react';
import {ICellTextProps} from 'ka-table/props';
import {dateTimeCell} from './ApiDataTableStyles';
import {convertToUTC, getLocalizedDateTimeStrings, MILLIS_IN_SECOND} from '../../utils/dates';
import {ITheme} from '../../styles/themes/models';

export interface IThemedCellTextProps extends ICellTextProps {
  theme: ITheme;
}
export interface IDateTimeCellTextProps extends IThemedCellTextProps {
  convertToUtc?: boolean;
  isDateOnly: boolean;
  multiLine: boolean;
}

const MILLENIUM_TICKS = 946713600000;

export const DateCell: React.FC<IDateTimeCellTextProps> = ({dispatch, value, convertToUtc, isDateOnly, multiLine}) => {
  if (!value) {
    return null;
  }

  if (typeof value === 'string' && value.indexOf('-') < 0 && value.indexOf('/') < 0) {
    // If the API returns a numeric string, parse it.
    value = Number(value);
  }
  if (value < MILLENIUM_TICKS) {
    // Assume value is in seconds rather than milliseconds if < 1/1/2000
    value *= MILLIS_IN_SECOND;
  }

  const dt = new Date(value);
  const {date, time} = getLocalizedDateTimeStrings(convertToUtc ? convertToUTC(dt) : dt);

  if (isDateOnly) {
    return (<span>{date}</span>);
  }

  return multiLine ? (
    <>
      <span className={dateTimeCell}>{date}</span>
      <span className={dateTimeCell}>{time}</span>
    </>
  ) : (
    <span>
      {date} {time}
    </span>
  );
};
