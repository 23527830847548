import React from 'react';
import {NavLink} from 'react-router-dom';

import {getLinkNotSelected, getLinkSelected} from './LinkStyles';
import {useAppSelector} from '../../../app/hooks';
import {selectCachedUserSelection} from '../../../app/slices/cachedStateSlice';
import {ITheme} from '../../../common/styles/themes/models';

interface IProps {
  theme: ITheme;
};

const SelectBusinessLink = ({theme}: IProps) => {
  const userSelection = useAppSelector(selectCachedUserSelection);
  return (
    <NavLink exact to="/select-business" className={getLinkNotSelected(theme)} activeClassName={getLinkSelected(theme)}>
      {userSelection}
    </NavLink>
  );
};

export default SelectBusinessLink;
