import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {ForecastVersionIdentifier} from '../../common/apis/models/forecast';

interface IForecastState {
  forecastId: string | null;
  versionId: number | null;
}

const FORECAST_URL_REGEX = /\/forecasts\/(?<forecastId>[a-f0-9-]+)\/versions\/(?<versionId>\d+)\/?/ig;
const {
  groups: {
    forecastId = null as string | null,
    versionId = null as string | null,
  } = {}
} = (FORECAST_URL_REGEX.exec(window.location.href) || {}) as any;

const initialState: IForecastState = {
  forecastId,
  versionId: versionId ? Number(versionId) : null,
};

const forecastStateSlice = createSlice({
  name: 'forecastState',
  initialState,
  reducers: {
    clearForecastVersionIdentifier: (state) => {
      state.forecastId = null;
      state.versionId = null;
    },
    setForecastVersionIdentifier: (state, action: PayloadAction<ForecastVersionIdentifier>) => {
      state.forecastId = action.payload?.forecastId || null;
      state.versionId = action.payload?.versionId || null;
    },
  },
});

export const {clearForecastVersionIdentifier, setForecastVersionIdentifier} = forecastStateSlice.actions;

export const selectForecastVersionIdentifier = (state: RootState) => ({
  // Make sure both fields are populated before returning either of them
  forecastId: (state.forecastState.versionId && state.forecastState.forecastId) || null,
  versionId: (state.forecastState.forecastId && state.forecastState.versionId) || null,
});

export default forecastStateSlice.reducer;
