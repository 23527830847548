import React from 'react';
import {NavLink} from 'react-router-dom';
import {css} from 'emotion';
import logo from '../assets/logo.png';
import {ITheme} from '../../../common/styles/themes/models';

export const getLogoSelected = (theme: ITheme) => (css`
  background-color: ${theme.Background};
`);

const getLogoNotSelected = (theme: ITheme) => (css`
  cursor: pointer;
  height: 3em;
  &:hover {
    background-color: ${theme.RowAlternate};
  }
`);

interface IProps {
  canViewDashboard: boolean;
  hasUserSelection: boolean;
  theme: ITheme;
};

const LogoLink = ({canViewDashboard, hasUserSelection, theme}: IProps) => {
  return (
    <NavLink exact to={hasUserSelection && canViewDashboard ? '/': '/select-business'} activeClassName={getLogoSelected(theme)} className={getLogoNotSelected(theme)}>
      <img src={logo} className={getLogoNotSelected(theme)} alt="Topline" />
    </NavLink>
  );
};

export default LogoLink;
