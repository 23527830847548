// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {useContext} from 'react';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import type {RootState, AppDispatch} from './store';
import {AuthContext, AuthTokenPayload} from './AuthProvider';
import {ForecastContext, ForecastContextType} from './ForecastProvider';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAuthContext: () => AuthTokenPayload = () => useContext(AuthContext);
export const useForecastContext: () => ForecastContextType = () => useContext(ForecastContext);
