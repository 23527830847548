import React, {useEffect, useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import AppLayout from '@amzn/meridian/app-layout';
import Theme from "@amzn/meridian/theme"
import {useMediaQuery} from 'react-responsive';
import {selectCachedUserSelection} from '../app/slices/cachedStateSlice';
import {selectForecastVersionIdentifier} from '../app/slices/forecastSlice';
import {selectTheme} from './slices/themeSlice';
import {ForecastRecord} from '../common/apis/models/forecast';
import {WIDE_NAV_QUERY} from '../common/utils/layout';
import {formatFriendlyId, formatForecastName} from '../common/utils/string';
import {useAppSelector, useAuthContext, useForecastContext} from './hooks';
import AdminPage from '../features/admin-page/AdminPage';
import DashboardPage from '../features/dashboard-page/DashboardPage';
import HelpPage from '../features/help-page/HelpPage';
import NavBar from '../features/navigation-bar/NavBar';
import NavBarSide from '../features/navigation-bar-side/NavBarSide';
import SelectBusinessPage from '../features/select-business-page/SelectBusinessPage';
import ErrorPage from '../features/error-page/ErrorPage';
import UnauthorizedPage from '../features/unauthorized-page/UnauthorizedPage';
import HistoryPage from '../features/history-page/HistoryPage';
import ForecastSummaryPage from '../features/forecast-summary-page/ForecastSummaryPage';
import MetadataPage from '../features/metadata-page/MetadataPage';
import OverlayPage from '../features/overlay-page/OverlayPage';
import {OverlayMetricLevel} from '../features/overlay-page/models';
import getSideNavigationDefinition from '../features/navigation-bar-side/getSideNavigationDefinition';

const formatTitle = (forecast: ForecastRecord | null) => {
  if (!forecast) { return ''; }
  return `${formatFriendlyId(forecast)} - ${formatForecastName(forecast)} - ${forecast.description} `;
}

const ToplineApp = () => {
  const userSelection = useAppSelector(selectCachedUserSelection);
  const forecastSelection = useAppSelector(selectForecastVersionIdentifier);
  const theme = useAppSelector(selectTheme);
  const authProps = useAuthContext();
  const permissionSet = authProps.permissionsSet;
  const forecastProps = useForecastContext();
  const {forecast, forecastState} = forecastProps;
  const [pageTitle, setPageTitle] = useState<string | void>();
  const isWidescreen = useMediaQuery(WIDE_NAV_QUERY);
  const isAuthorized = Boolean(userSelection);
  const RedirectToSelection = <Redirect to="/select-business" />;
  const RedirectToUnauthorized = <Redirect to="/unauthorized" />;
  const redirect = (hasPermission: boolean, page: JSX.Element) => (isAuthorized ? (hasPermission ? page: RedirectToUnauthorized) : RedirectToSelection);

  const formattedForecastTitle = forecastSelection?.forecastId ? formatTitle(forecastProps.forecast) : '';
  const formattedTitle = pageTitle ? `${formattedForecastTitle}${formattedForecastTitle ? ' - ' : ''}${pageTitle}` : formattedForecastTitle;

  useEffect(() => {
    document.title = formattedTitle ?  `F3 Topline - ${formattedTitle}` : 'F3 Topline';
  }, [formattedTitle]);

  const getPageProps = (pageKey: string) => ({
    ...forecastProps,
    key: `${userSelection}:${forecast?.forecastId || ''}:${forecast?.versionId || ''}:${pageKey}`,
    setPageTitle,
  });
  const forecastPath = forecastSelection?.forecastId ?
    `/forecasts/${forecastSelection.forecastId}/versions/${forecastSelection.versionId}` : null;

  return (
    <BrowserRouter>
      <Theme tokens={theme.MeridianTheme}>
        <AppLayout headerComponent={NavBar} sidebarComponent={NavBarSide} backgroundColor={theme.Background}>
          <NavBar pageTitle={formattedTitle} permissionSet={permissionSet} />
          { forecastPath && <NavBarSide sections={getSideNavigationDefinition(forecast, forecastPath, forecastState, permissionSet, isWidescreen)} /> }
          <Switch>
            <Route path="/error">
              <ErrorPage />
            </Route>
            <Route path="/unauthorized" exact={true}>
              <UnauthorizedPage />
            </Route>
            <Route path="/select-business" exact={true}>
              <SelectBusinessPage />
            </Route>
            <Route path="/help" exact={true}>
              <HelpPage />
            </Route>
            <Route path="/admin" exact={true}>
              {redirect(permissionSet.has('canViewAdmin'), <AdminPage />)}
            </Route>
            <Route path="/" exact={true}>
              {redirect(permissionSet.has('canViewDashboard'), <DashboardPage {...getPageProps('dashboard')} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/history" exact={true}>
              {redirect(permissionSet.has('canViewHistory'), <HistoryPage {...getPageProps('history')} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/summary" exact={true}>
              {redirect(permissionSet.has('canViewSummary'), <ForecastSummaryPage {...getPageProps('summary')} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/metadata" exact={true}>
              {redirect(permissionSet.has('canViewSummary'), <MetadataPage {...getPageProps('metadata')} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/price-index" exact={true}>
              {redirect(permissionSet.has('canOverlayForecast'), <OverlayPage metricId="PRICE_INDEX" metricLevel={OverlayMetricLevel.FC_LEVEL} metricName="Price Index" {...getPageProps('overlay-price-index')} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/discounts" exact={true}>
              {redirect(permissionSet.has('canOverlayForecast'), <OverlayPage metricId="DISCOUNTS" metricLevel={OverlayMetricLevel.FC_LEVEL} metricName="Discounts" {...getPageProps('overlay-discounts')} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/covid-mobility" exact={true}>
              {redirect(permissionSet.has('canOverlayForecast'), <OverlayPage metricId="COVID_MOBILITY" metricLevel={OverlayMetricLevel.FC_LEVEL} metricName="COVID Mobility" {...getPageProps('overlay-covid-mobility')} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/uft" exact={true}>
              {redirect(permissionSet.has('canOverlayForecast'), <OverlayPage metricId="UFT" metricLevel={OverlayMetricLevel.NETWORK_LEVEL} metricName="UFT" {...getPageProps('overlay-uft')} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/oocsd" exact={true}>
              {redirect(permissionSet.has('canOverlayForecast'), <OverlayPage metricId="OOCSD" metricLevel={OverlayMetricLevel.NETWORK_LEVEL} metricName="OOC (Same Day)" {...getPageProps('overlay-ooc-sd')} />)}
            </Route>
            <Route path="/forecasts/:forecastId/versions/:versionId/oocnd" exact={true}>
              {redirect(permissionSet.has('canOverlayForecast'), <OverlayPage metricId="OOCND" metricLevel={OverlayMetricLevel.NETWORK_LEVEL} metricName="OOC (Next Day)" {...getPageProps('overlay-ooc-nd')} />)}
            </Route>
          </Switch>
        </AppLayout>
      </Theme>
    </BrowserRouter>
  );
};

export default ToplineApp;
