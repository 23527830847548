import React, {useEffect} from 'react';
import {IPageProps} from './models';

interface IToplinePageProps extends IPageProps {
  title?: string;
}

const ToplinePage = ({children, title, setPageTitle}: React.PropsWithChildren<IToplinePageProps>) => {
  useEffect(() => {
    // Set the header title suffix if specified
    if (title) {
      setPageTitle(title);
    } 
  }, [setPageTitle, title]);

  return (
    <>
      {children}
    </>
  );
};

export default ToplinePage;
