import React, {useCallback, useState} from 'react';
import ActionPopover from '../../common/components/ActionPopover';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
// import Toggle from '@amzn/meridian/toggle';
import {postDiscardForecast} from '../../common/apis/forecasting-api-client';
import {IActionButtonProps} from './models';
import ForecastTitle from './ForecastTitle';

const DiscardForecastLinkButton = ({anchorRef, forecast, open, setOpen}: IActionButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [discardAllVersions/*, setDiscardAllVersions*/] = useState(true);
  const actionCallback = useCallback(() => {
    if (isLoading) { return Promise.resolve(); }
    setIsLoading(true);
    return postDiscardForecast({
      businessId: forecast.businessId,
      country: forecast.country,
      forecastId: forecast.forecastId,
      versionId: discardAllVersions ? null : forecast.versionId,
    })
    .catch((err) => {
      console.log('ERROR DISCARDING RECORD', err);
      setIsLoading(false);
    })
    .then(() => {
      setIsLoading(false);
      window.location.href = '/';
    });
  }, [discardAllVersions, isLoading, forecast.businessId, forecast.country, forecast.forecastId, forecast.versionId]);
  const availableVersions = (forecast.versions.forecast || []).length || 1;
  const versionCount = discardAllVersions ? availableVersions : 1;

  return (
    <ActionPopover
      actionButtonText="Yes"
      actionCallback={actionCallback}
      actionIsLoading={isLoading}
      actionTitle="Discard Forecast"
      anchorRef={anchorRef}
      cancelButtonText="No"
      disabled={isLoading}
      open={open}
      setOpen={setOpen}
    >
      <ForecastTitle forecast={forecast} />
      {
        // TODO: Uncomment to allow disacrding specific versions.
        // availableVersions > 1 ? (
        //   <Row>
        //     <Toggle checked={discardAllVersions} onChange={setDiscardAllVersions}>
        //       Discard all versions
        //     </Toggle>
        //   </Row>
        // ) : null
      }
      <Row>
        <Text>
        {
          versionCount === 1 ? 'Are you sure that you want to discard this forecast?' :
            `Are you sure that you want to discard all ${versionCount} versions of this forecast?`
        }
        </Text>
      </Row>
    </ActionPopover>
  );
};

export default DiscardForecastLinkButton;
