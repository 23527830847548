import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import Card, {CardHeader} from '@amzn/meridian/card';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Row from '@amzn/meridian/row';
import {css} from 'emotion';
import {capitalCase} from 'change-case';
import {useAppSelector} from '../../app/hooks';
import {ForecastRecordWithValues, ForecastValue} from '../../common/apis/models/getForecastResponse';
import ApiChart, {ApiChartRecord} from '../../common/components/ApiChart';
import {IPageProps} from '../../common/page/models';
import ToplinePage from '../../common/page/ToplinePage';
import {ITheme} from '../../common/styles/themes/models';
import {WIDESCREEN_QUERY} from '../../common/utils/layout';
import {selectTheme} from '../../app/slices/themeSlice';
import {convertDateTicks, getLocalizedDateTimeStrings} from '../../common/utils/dates';
import DataFilter from './DataFilter';
import {extractSitesFromForecast, SiteData} from '../../common/components/SiteFilter';

interface ExtractedData {
  maxDate: string;
  minDate: string;
  values: ApiChartRecord[];
}

// NOTE: Changing the metric order here will change the order of the cards on the page
const CHART_METRICS = [
  {key: 'UNITS', title: 'Units'},
  {key: 'ORDERS', title: 'Orders'},
  {key: 'CUSTOMERS', title: 'Customers'},
  {key: 'OPS', title: 'OPS'}
];
const CHART_CARDS = [CHART_METRICS.slice(0, 2), CHART_METRICS.slice(2, 4)];

const DECIMAL_PRECISION = 100;
const CARD_WIDTH = 500;
const CARD_HEIGHT = 400;

const CHART_SERIES = ['NEW', 'REPEAT', 'AGGREGATE'];
const CHART_DEFINITIONS = CHART_CARDS.map(row => row.map(({key, title}) => ({
  key,
  title,
  series: CHART_SERIES.map(metricType => ({key: metricType, title: capitalCase(metricType)})),
})));

const ForecastSummaryPage = ({forecast}: IPageProps) => {
  const isWidescreen = useMediaQuery(WIDESCREEN_QUERY);
  const mastheadAndColumnSpacing = css`
    padding: 0.7% 1.5%;
  `;

  const cardStyle = css`
    width: ${isWidescreen ? 50 : 100}%;
    padding-right: 16px;
    padding-top: 16px;

    .recharts-responsive-container {
      overflow: hidden;
    }
  `;

  const chartContainerStyle = css`
    width: 100%;
    height: ${CARD_HEIGHT}px;
  `;

  const theme = useAppSelector(selectTheme) as ITheme;
  const [allSitesList, setAllSitesList] = useState<SiteData>({
    siteIdentifiers: new Set<string>(),
    siteNames: new Set<string>(),
    siteMapping: {} as {[siteName: string]: Set<string>},
  });
  const [siteIdentifiers, setSites] = useState<string[] | undefined>();
  // TODO: For future use - allow user to select which metrics to display on dashboard.
  const [metrics /*, setMetrics*/] = useState(CHART_METRICS.map(cm => cm.key));
  const [showDataLabels, setShowDataLabels] = useState(true);
  const [startDate, setStartDateValue] = useState('');
  const [endDate, setEndDateValue] = useState('');

  useEffect(() => {
    const siteData = extractSitesFromForecast(forecast);
    setAllSitesList(siteData);
    setSites(Array.from(siteData.siteIdentifiers));
  }, [forecast]);

  const extractedData = metrics.reduce((extracted: {[metric: string]: ExtractedData}, metric: string) => {
    extracted[metric] = extractData(forecast, metric, startDate, endDate, siteIdentifiers)
    return extracted;
  }, {});
  const dateRange = Object.entries(extractedData).reduce((acc: {minDate: string, maxDate: string}, extracted: any[]) => {
    if (!acc.minDate || extracted[1].minDate < acc.minDate) { acc.minDate = extracted[1].minDate; }
    if (!acc.maxDate || extracted[1].maxDate > acc.maxDate) { acc.maxDate = extracted[1].maxDate; }
    return acc;
  }, {minDate: '', maxDate: ''});

  const chartDefinitions = isWidescreen ? CHART_DEFINITIONS :
    CHART_DEFINITIONS[0].concat(CHART_DEFINITIONS[1]).map(def => [def]);
;
  return (
    <Column className={mastheadAndColumnSpacing}>
      <DataFilter
        isWidescreen={isWidescreen}
        endDate={endDate || dateRange.maxDate}
        filterValue={siteIdentifiers || [] as string[]}
        maxDate={dateRange.maxDate}
        minDate={dateRange.minDate}
        setEndDateValue={setEndDateValue}
        setFilter={setSites}
        setShowDataLabels={setShowDataLabels}
        setStartDateValue={setStartDateValue}
        showDataLabels={showDataLabels}
        siteData={allSitesList}
        startDate={startDate || dateRange.minDate}
      />
      {
        chartDefinitions.map((row, ix) => 
          (<Row key={`chart-row-${ix}`} width="fill">
            {
              row.map((chart) => (
                <div key={`chart-container-${chart.key}`} className={cardStyle}>
                  <Card minWidth={CARD_WIDTH}>
                    <CardHeader>
                      <Heading level={2} type="h500">
                        {chart.title}
                      </Heading>
                    </CardHeader>
                    <div className={chartContainerStyle}>
                      <ApiChart
                        loading={!forecast}
                        chartKey={chart.key}
                        data={extractedData[chart.key].values}
                        theme={theme}
                        xAxisKey="date"
                        yAxes={chart.series.map(({key, title}) => ({
                          title,
                          key: `${title}-${ix}-${Math.round(Math.random()*100)}`,
                          dataKey: title,
                          color: key === 'NEW' ? theme.ChartNewSeries :
                            key === 'REPEAT' ? theme.ChartRepeatSeries :
                            theme.ChartAggregateSeries
                        }))}
                        showDataLabels={showDataLabels}
                        showLegend={true}
                      />
                    </div>
                  </Card>
                </div>
              ))
            }
          </Row>)
        )
      }
    </Column>
  );
};

function extractData(
  forecast: ForecastRecordWithValues | null | undefined,
  metric: string,
  startDate: string,
  endDate: string,
  siteIdentifiers: string[] | void
): ExtractedData {
  const extractedData = {
    values: [],
    minDate: '',
    maxDate: '',
  } as ExtractedData;

  if (!forecast || !forecast.sites) { return extractedData; }
  const aggregatedData: {
    [date: string]: {
      [metricType: string]: number;
    }
  } = {};

  const startDateMonth = startDate.substring(0, 7);
  const endDateMonth = endDate.substring(0, 7);
  const allowedSites = new Set(siteIdentifiers || Object.keys(forecast.sites));
  Object.entries(forecast.sites).forEach(([siteId, siteData]) => {
    if (!allowedSites.has(siteId)) { return; }
    const {values} = siteData;
    Object.keys(values).forEach((dataMetric: string) => {
      if (dataMetric !== metric) { return; }
      Object.entries(values[metric]).forEach(([metricType, metricValues]) => {
        metricValues.map((forecastValue: ForecastValue) => {
          const date = getLocalizedDateTimeStrings(convertDateTicks(forecastValue.date)).date.substring(0, 7);
          if (!extractedData.minDate || date < extractedData.minDate) { extractedData.minDate = date; }
          if (!extractedData.maxDate || date > extractedData.maxDate) { extractedData.maxDate = date; } // TODO: should be last day of month
          if (startDateMonth && date < startDateMonth) { return null; }
          if (endDateMonth && date > endDateMonth) { return null; }
          return {
            date: date,
            value: forecastValue.value,
          };
        }).filter(Boolean).forEach((formattedValue) => {
          const dateAggregatedData = aggregatedData[formattedValue!.date] = aggregatedData[formattedValue!.date] || {};
          dateAggregatedData[metricType] = (dateAggregatedData[metricType] || 0) + formattedValue!.value;
        });
      });
    });
  })
  extractedData.values = Object.keys(aggregatedData).map((date: string) => {
    const dateSeries = {date} as ApiChartRecord;
    Object.keys(aggregatedData[date]).forEach((metricType: string) => {
      dateSeries[capitalCase(metricType)] = Math.round(aggregatedData[date][metricType] * DECIMAL_PRECISION) / DECIMAL_PRECISION;
    });
    return dateSeries;
  });

  // Add day component to min/max for use in filter
  extractedData.minDate = `${extractedData.minDate}-01`;
  extractedData.maxDate = `${extractedData.maxDate}-01`;

  return extractedData;
}

export default (props: IPageProps) => (
  <ToplinePage title="Summary" {...props}>
    <ForecastSummaryPage {...props} />
  </ToplinePage>
);
