import omit from 'lodash.omit';
import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {GetForecastOverlayListResponse} from '../models/getForecastOverlayListResponse';
import {handleError} from './handleError';

export interface GetForecastOverlayListParams {
  businessId: string;
  country: string;
  forecastId: string;
  versionId: number;
  overlayMetric: string;
  siteIdentifiers: string[] | undefined;
  offset: number | undefined;
  pageSize: number | undefined;
  sortKey: string | undefined;
  sortDirection: string | undefined;
}

export const getForecastOverlayListEndpoint = async (
  forecastingApiGatewayClient: ApiGatewayClient,
  params: GetForecastOverlayListParams
): Promise<GetForecastOverlayListResponse | void> => {
  try {
    const {businessId, country, forecastId, overlayMetric, siteIdentifiers} = params;
    const queryParams = filterNullEmptyOrUndefinedKeys({
      ...omit(params, ['businessId', 'country', 'forecastId', 'overlayMetric', 'siteIdentifiers']),
    });
    if (siteIdentifiers && siteIdentifiers.length) {
      queryParams.sites = siteIdentifiers.join(',');
    }
    return (
      await forecastingApiGatewayClient.invokeApi(
        {businessId, country, forecastId, overlayMetric},
        '/business/{businessId}/country/{country}/forecast/{forecastId}/overlay/{overlayMetric}',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGatewayErrorResponse);
  }
};
