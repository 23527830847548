import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import {ForecastRecord} from '../models/forecast';
import {handleError} from './handleError';

export interface PostForecastRestoreParams {
  businessId: string;
  country: string;
  forecastId: string;
  versionId?: number | null;
}

export const postForecastRestoreEndpoint = async (
  forecastingApiGatewayClient: ApiGatewayClient,
  params: PostForecastRestoreParams
): Promise<ForecastRecord | void> => {
  try {
    const {businessId, country, forecastId, versionId} = params;
    const queryParams = versionId ? { versionId: versionId.toString() } : {};
    return (
      await forecastingApiGatewayClient.invokeApi(
        {businessId, country, forecastId},
        '/business/{businessId}/country/{country}/forecast/{forecastId}/restore',
        'POST',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGatewayErrorResponse);
  }
};
