import React, {useCallback} from 'react';
import Icon from '@amzn/meridian/icon';
import Toggle from '@amzn/meridian/toggle';
import asleep from '@amzn/meridian-tokens/base/icon/asleep';
import {ITheme} from '../../../common/styles/themes/models';
import {useAppDispatch} from '../../../app/hooks';
import {setThemeName} from '../../../app/slices/themeSlice';
import {ThemeName} from '../../../common/styles/themes/models';

interface IProps {
  theme: ITheme;
};

const DarkToggleLink = ({theme}: IProps) => {
  const dispatch = useAppDispatch();
  const toggleDarkMode = useCallback(() => {
    window.localStorage.setItem('isDarkMode', `${!theme.isDarkMode}`);
    dispatch(setThemeName(theme.isDarkMode ? ThemeName.ExcelsiorFresh : ThemeName.Dark));
  }, [dispatch, theme.isDarkMode]);
  return (
    <Toggle checked={theme.isDarkMode} onChange={toggleDarkMode}>
      <Icon tokens={asleep} />
    </Toggle>
  );
};

export default DarkToggleLink;
