import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Button from '@amzn/meridian/button';
import {css} from 'emotion';
import {AppDispatch} from '../../../app/store';
import {setForecastVersionIdentifier} from '../../../app/slices/forecastSlice';
import {IThemedCellTextProps} from '../../../common/components/ApiDataTable/ApiDataTableCells';
import {ITheme} from '../../../common/styles/themes/models';
import {ForecastDetail, ForecastDetailedStatus} from '../utils/formatForecastData';
import {getTableStyles} from './ForecastListTableStyles';

interface ForecastDetailsProps extends IThemedCellTextProps {
  appDispatch: AppDispatch;
  history: RouteComponentProps['history'];
};

const idTextContainer = css`
  padding: 8px 0;
`;

const idTextStyle = css`
  font-size: 16px;
  line-height: 24px;
  padding: 0 16px;
`;

const getStatusStyle = (status: ForecastDetailedStatus, theme: ITheme): string => (css`
  color: ${status.isError ? theme.TextError : status.isProcessing ? theme.TextInProgress : status.isFinal ? theme.TextSuccess : theme.TextPrimary};
  font-weight: ${status.isError || status.isFinal || status.isProcessing ? 'bold' : 'normal'};
`);

export const ForecastDetailsCell: React.FC<ForecastDetailsProps> = ({dispatch, appDispatch, history, value, theme}) => {
  const {forecastDetailsLinkCell} = getTableStyles(theme);
  const record = value as ForecastDetail;
  const onClickHandler = (linkUrl: string) => {
    appDispatch(setForecastVersionIdentifier({
      forecastId: record.forecastId,
      versionId: record.versionId,
    }));
    return history.push(linkUrl);
  };
  return record.isProcessing ? (
    <div className={idTextContainer}>
      <span className={idTextStyle}>{record.friendlyId || ''}</span>
    </div>
  ) : (
    <Button className={forecastDetailsLinkCell} onClick={onClickHandler} href={`/forecasts/${record.forecastId}/versions/${record.versionId}/summary`} type="link">
      {record.friendlyId || ''}
    </Button>
  );
};

export const ForecastStatusCell: React.FC<IThemedCellTextProps> = ({dispatch, value, theme}) => {
  const record = value as ForecastDetailedStatus;
  return (
    <div className={getStatusStyle(record, theme)}>
      {record.formattedStatus}
    </div>
  );
};
