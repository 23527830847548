import React from 'react';
import Select, {SelectOption} from '@amzn/meridian/select';

import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {selectBusinessSelector, setBusinessAFS, setBusinessUFF} from '../../../app/slices/businessSlice';

const options = [
  {label: 'AFS', value: 'AFS'},
  {label: 'UFF', value: 'UFF'},
];

const BusinessSelector = () => {
  const businessSelection = useAppSelector(selectBusinessSelector);
  const dispatch = useAppDispatch();

  const handleChange = (business: string) => {
    switch (business) {
      case 'AFS':
        dispatch(setBusinessAFS());
        break;
      case 'UFF':
        dispatch(setBusinessUFF());
        break;
    }
  };

  return (
    <Select value={businessSelection} onChange={handleChange} width={300} label="Business" disabled={false} popoverMaxHeight={250}>
      {options.map((option, index) => (
        <SelectOption label={option.label} value={option.value} key={index} />
      ))}
    </Select>
  );
};

export default BusinessSelector;
