import {css} from 'emotion';
import {ITheme} from '../../../common/styles/themes/models';

export const getLinkNotSelected = (theme: ITheme) => (css`
  position: relative;
  z-index: 0;
  cursor: pointer;
  border: 0px none;
  appearance: none;
  outline: currentcolor none medium;
  display: flex;
  align-items: center;
  -moz-box-align: center;
  color: ${theme.TextPrimary};
  padding: 0px 16px;
  align-self: stretch;
  white-space: nowrap;
  transition: color 100ms ease 0s, background-color 100ms ease 0s;
  transform: translateZ(0px);
  text-decoration: none;
  &:hover {
    background-color: ${theme.RowAlternate};
  }
`);

export const getLinkSelected = (theme: ITheme) => (css`
  background-color: ${theme.RowHighlight};
`);
