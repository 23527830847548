import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {ThemeName} from '../../common/styles/themes/models';
import {getTheme} from '../../common/styles/themes';

interface IThemeState {
  theme: ThemeName;
}

const initialState: IThemeState = {
  theme: Boolean(window.localStorage.getItem('isDarkMode') === 'true') ? ThemeName.Dark : ThemeName.ExcelsiorFresh,
};

const themeSlice = createSlice({
  name: 'themeState',
  initialState,
  reducers: {
    setThemeName: (state, action: PayloadAction<ThemeName>) => {
      state.theme = action.payload;
    },
  },
});

export const {setThemeName} = themeSlice.actions;

export const selectThemeName = (state: RootState) => state.themeState.theme;
export const selectTheme = (state: RootState) => getTheme(state.themeState.theme);

export default themeSlice.reducer;
