import React from 'react';
import Column from '@amzn/meridian/column';
import Text from '@amzn/meridian/text';
import {css} from 'emotion';

const headerStyle = css`
  padding-top: 3%;
  padding-left: 5%;
`;

const HelpPage = () => {
  return (
    <Column>
      <Text type="h400" alignment="left" className={headerStyle}>
        Help Page
      </Text>
    </Column>
  );
};

export default HelpPage;
