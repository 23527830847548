import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {freshColors} from '../../styles/colors';
import {ITheme} from '../../styles/themes/models';
import ChartLoading from './ChartLoading';
import DataValueLabel from './DataValueLabel';
import DateAxis from './DateAxis';
import LargeNumberAxis from './LargeNumberAxis';
import {format as dateFormat, parse as parseDate} from 'date-fns';

export interface ApiChartRecord {
  [key: string]: any
};

interface YAxisDefinition {
  key: string;
  dataKey: string;
  title: string;
  color?: string;
};

interface IProps {
  chartKey: string;
  data: ApiChartRecord[];
  xAxisKey: string;
  yAxes: YAxisDefinition[];
  width?: number;
  height?: number;
  loading?: boolean;
  showLegend?: boolean;
  showDataLabels?: boolean;
  theme: ITheme;
};

const DEFAULT_SERIES_COLORS = [
  freshColors.blue,
  freshColors.green,
  freshColors.orange,
  freshColors.red,
  freshColors.purple,
  freshColors.pink,
  freshColors.magenta,
];

const ApiChart = (props: IProps) => {
  const {chartKey, data, height, loading, showDataLabels, showLegend, theme, width, xAxisKey, yAxes} = props;

  // Shuffle default colors
  const defaultChartColors = DEFAULT_SERIES_COLORS.slice().sort(() => Math.random() - 0.5);

  return (
    (loading || (loading === undefined && (!Array.isArray(data) || !data.length))) ?
    (<ChartLoading />) :
    (<ResponsiveContainer width={width} height={height}>
    {
      (<LineChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={xAxisKey}
          tick={<DateAxis />} />
        <YAxis
          domain={[0, (dataMax: number) => Math.ceil(dataMax * 1.1)]}
          type="number"
          scale="linear"
          tick={<LargeNumberAxis />}
          tickCount={5} />
        <Tooltip
          contentStyle={{backgroundColor: theme.Background, borderColor: theme.RowBorder}}
          labelFormatter={(value: string) => (value && value !== 'auto') ? dateFormat(parseDate(value, 'yyyy-MM', new Date()), 'MMMM yyyy'): ''}
          formatter={(value: string, name: string) => value ? [Number(value).toLocaleString(), name] : [null, name]}
        />
        { showLegend === true ? <Legend verticalAlign="top" align="right" /> : null }
        {
          yAxes.map((yAxis: YAxisDefinition, ix: number) => {
            return (<Line
              key={`data-${chartKey}-y-axis-${yAxis.key}`}
              /* @ts-ignore - the typescript defs for Line are wrong and don't have this label property. */
              label={showDataLabels === true ? <DataValueLabel stroke={theme.TextPrimary} /> : null}
              type="monotone"
              dataKey={yAxis.dataKey}
              name={yAxis.title}
              stroke={yAxis.color || defaultChartColors[ix % defaultChartColors.length]}
              activeDot={{r: 8}}
            />);
          })
        }
      </LineChart>)
    }
    </ResponsiveContainer>));
}

export default ApiChart;
