import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {ITableProps, kaReducer, Table} from 'ka-table';
import {ICellTextProps} from 'ka-table/props';
import {
  hideLoading,
  loadData,
  setSingleAction,
  showLoading,
  updateData,
  updatePageIndex,
  updatePagesCount,
} from 'ka-table/actionCreators';
import {DataType, SortingMode, PagingPosition, SortDirection, ActionType} from 'ka-table/enums';
import {DispatchFunc} from 'ka-table/types';
import 'ka-table/style.scss';
import {Column} from 'ka-table/models';

import {useAppDispatch} from '../../../app/hooks';
import {AppDispatch} from '../../../app/store';
import {getForecastList} from '../../../common/apis/forecasting-api-client';
import {GetForecastListResponse, ForecastListSortKeys} from '../../../common/apis/models/getForecastListResponse';
import {getTableStyles} from './ForecastListTableStyles';
import {useAppSelector} from '../../../app/hooks';
import formatForecastData from '../utils/formatForecastData';
import {ITheme} from '../../../common/styles/themes/models';
import {selectCachedUserSelection} from '../../../app/slices/cachedStateSlice';
import {selectTheme} from '../../../app/slices/themeSlice';
import {ForecastDetailsCell, ForecastStatusCell} from './ForecastListCells';
import ForecastListHeader from './ForecastListHeader';
import PageLoading from '../../../common/components/PageLoading';
import {DateCell} from '../../../common/components/ApiDataTable/ApiDataTableCells';
import {getISODate, /* MILLIS_IN_DAY, */ MILLIS_IN_THIRTY_DAYS} from '../../../common/utils/dates';

const DEFAULT_PAGE_SIZE = 10;
const ALLOWED_PAGE_SIZES = [10, 20, 50];

const getColumnTypeMapping = (
  appDispatch: AppDispatch,
  history: RouteComponentProps['history'],
  theme: ITheme,
  props: React.PropsWithChildren<ICellTextProps>
) => {
  switch (props.column.key) {
    case 'forecastDetail':
      return <ForecastDetailsCell history={history} theme={theme} appDispatch={appDispatch} {...props} />;
    case 'forecastDetailedStatus':
      return <ForecastStatusCell theme={theme} {...props} />;
    case 'updatedAt':
      return <DateCell theme={theme} isDateOnly={false} multiLine={false} {...props} />;
}
};

const getDefaultStartDate = () => {
  return getISODate(new Date(Date.now() - (6 * MILLIS_IN_THIRTY_DAYS))); // 180 days
};

const getDefaultEndDate = () => {
  // TODO: Our mock data is currently future-dated, so setting this date out in the future for now.
  // return getISODate(new Date(Date.now() + MILLIS_IN_DAY));
  return getISODate(new Date(Date.now() + (MILLIS_IN_THIRTY_DAYS * 2)));
};

const buildTable = (forecastList: GetForecastListResponse | void): ITableProps => {
  let columns: Column[] = [
    {key: 'forecastDetail', title: 'Id', dataType: DataType.Object, width: 30},
    {key: 'forecastName', title: 'Forecast', dataType: DataType.String, width: 40},
    {key: 'description', title: 'Description', dataType: DataType.String, width: 70},
    {key: 'model', title: 'Model', dataType: DataType.String, width: 30},
    {key: 'updatedAt', title: 'Last Updated', dataType: DataType.String, width: 40, sortDirection: SortDirection.Descend},
    {key: 'forecastDetailedStatus', title: 'Status', dataType: DataType.Object, width: 30},
    {key: 'updatedBy', title: 'User', dataType: DataType.String, width: 50},
  ];

  const tablePropsInit: ITableProps = {
    columns,
    data: formatForecastData((forecastList && forecastList.forecasts) || []),
    rowKeyField: 'forecastId',
    sortingMode: SortingMode.SingleRemote,
    singleAction: loadData(),
    loading: {enabled: true},
    paging: {
      enabled: true,
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      pageSizes: ALLOWED_PAGE_SIZES,
      position: PagingPosition.Bottom,
    },
  };

  return tablePropsInit;
};

interface IProps {
  isWidescreen: boolean;
  history: RouteComponentProps['history'];
  forecastList: GetForecastListResponse | void;
  setForecastList: React.Dispatch<React.SetStateAction<void | GetForecastListResponse | undefined>>;
  businessId: string;
  country: string;
}

const ForecastListTable = ({isWidescreen, history, forecastList, setForecastList, businessId, country}: IProps) => {
  const appDispatch = useAppDispatch();
  const business = useAppSelector(selectCachedUserSelection)?.split('-')[0];
  const theme = useAppSelector(selectTheme);
  const [tableProps, changeTableProps] = useState(buildTable(forecastList));

  const [query, setSearchInput] = useState('');
  const [startTime, setStartDateValue] = useState(getDefaultStartDate());
  const [endTime, setEndDateValue] = useState(getDefaultEndDate());

  const dispatch: DispatchFunc = async (action) => {
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
    if (action.type === ActionType.UpdateSortDirection) {
      dispatch(updatePageIndex(0));
    } else if (action.type === ActionType.UpdatePageIndex || action.type === ActionType.UpdatePageSize) {
      dispatch(setSingleAction(loadData()));
    } else if (action.type === ActionType.LoadData) {
      dispatch(showLoading());
      const sortCol = tableProps.columns.find((c) => c.sortDirection);
      const pageSize = tableProps?.paging?.pageSize || DEFAULT_PAGE_SIZE;
      const currentPageIndex = tableProps?.paging?.pageIndex || 0;
      const offset = currentPageIndex > 0 ? pageSize * currentPageIndex : undefined;
      const result = await getForecastList({
        businessId,
        country,
        query,
        startTime: getDateTimeParam(startTime),
        endTime: getDateTimeParam(endTime),
        offset,
        pageSize,
        sortKey: sortCol ? sortCol.key : ForecastListSortKeys.UpdatedAt,
        sortDirection: sortCol ? sortCol.sortDirection : SortDirection.Descend,
      });
      dispatch(updatePagesCount(result ? Math.ceil(result.totalRecords / pageSize) : 0));
      dispatch(updateData(formatForecastData(result?.forecasts || [])));
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getForecastList({
      businessId,
      country,
      query,
      startTime: getDateTimeParam(startTime),
      endTime: getDateTimeParam(endTime),
      offset: undefined,
      pageSize: undefined,
      sortKey: ForecastListSortKeys.UpdatedAt,
      sortDirection: SortDirection.Descend,
    }).then((response) => {
      setForecastList(response);
      buildTable(response);
    });
  }, [businessId, country, query, startTime, endTime, setForecastList]);

  useEffect(() => {
    changeTableProps(buildTable(forecastList));
  }, [forecastList, changeTableProps, business]);

  if (forecastList) {
    const {tableStyle} = getTableStyles(theme);
    return (
      <div className={tableStyle}>
        <ForecastListHeader
          isWidescreen={isWidescreen}
          searchInput={query}
          startDate={startTime}
          endDate={endTime}
          setSearchInput={setSearchInput}
          setStartDateValue={setStartDateValue}
          setEndDateValue={setEndDateValue}
        />
        <Table
          {...tableProps}
          dispatch={dispatch}
          childComponents={{
            noDataRow: {
              content: () => 'No Data Found',
            },
            cellText: {
              content: getColumnTypeMapping.bind(getColumnTypeMapping, appDispatch, history, theme),
            },
          }}
        />
      </div>
    );
  } else {
    return <PageLoading />;
  }
};

function getDateTimeParam(value: string): Date | undefined {
  return value ? new Date(value) : undefined;
}

export default ForecastListTable;
