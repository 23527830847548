import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {useHistory} from 'react-router-dom';
import Column from '@amzn/meridian/column';
import {css} from 'emotion';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectForecastVersionIdentifier, clearForecastVersionIdentifier} from '../../app/slices/forecastSlice';
import {selectCachedBusiness, selectCachedCountry} from '../../app/slices/cachedStateSlice';

import ForecastListTable from './components/ForecastListTable';
import {GetForecastListResponse} from '../../common/apis/models/getForecastListResponse';
import {IPageProps} from '../../common/page/models';
import ToplinePage from '../../common/page/ToplinePage';
import {WIDESCREEN_QUERY} from '../../common/utils/layout';

const mastheadAndColumnSpacing = css`
  padding: 0.7% 1.5%;
`;

const DashboardPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [forecastList, setForecastList] = useState<GetForecastListResponse | void>();
  const businessSelection = useAppSelector(selectCachedBusiness);
  const countrySelection = useAppSelector(selectCachedCountry);
  const forecastIdentifier = useAppSelector(selectForecastVersionIdentifier);

  const isWidescreen = useMediaQuery(WIDESCREEN_QUERY);

  useEffect(() => {
    if (forecastIdentifier?.forecastId || forecastIdentifier?.versionId)
    dispatch(clearForecastVersionIdentifier());
  }, [dispatch, forecastIdentifier]);

  return (
    <Column className={mastheadAndColumnSpacing}>
      <ForecastListTable
        isWidescreen={isWidescreen}
        history={history}
        forecastList={forecastList}
        setForecastList={setForecastList}
        businessId={businessSelection!}
        country={countrySelection!}
      />
    </Column>
  );
};

export default (props: IPageProps) => (
  <ToplinePage title="Dashboard" {...props}>
    <DashboardPage />
  </ToplinePage>
);
