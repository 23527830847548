import tealLightTokens from '@amzn/meridian-tokens/theme/teal-light';
import {meridianColors} from '../colors';
import {ITheme} from './models';

// Quicksight colors
const rowBorder = '#ddd';

const excelsior: ITheme = {
  isDarkMode: false,
  MeridianTheme: tealLightTokens,
  ChartNewSeries: meridianColors.colorGreen500,
  ChartRepeatSeries: meridianColors.colorBlue400,
  ChartAggregateSeries: meridianColors.colorGray500,
  ErrorInfoBackground: '#cf1943',
  ErrorInfoText: meridianColors.colorGray0,
  NotificationInfoBackground: '#0073bb',
  NotificationInfoText: meridianColors.colorGray0,
  SuccessInfoBackground: '#1e8900',
  SuccessInfoText: meridianColors.colorGray0,
  Background: meridianColors.colorGray0,
  BackgroundInverted: meridianColors.colorGray800,
  TableShadow: 'rgba(0, 16, 75, 0.2)',
  RowHeaderBackground: '#eaeaea',
  RowHeaderBorder: rowBorder,
  RowHeader: meridianColors.colorSquidInk,
  RowPrimary: meridianColors.colorGray0,
  RowBorder: rowBorder,
  RowHighlight: '#c9e7f5',
  RowHighlightBorder: rowBorder,
  RowAlternate: '#f4f4f4',
  RowAlternateBorder: rowBorder,
  StickyColumnBackground: '#f0f0f0',
  StickyColumnBorder: rowBorder,
  StickyColumn: meridianColors.colorSquidInk,
  TextPrimary: meridianColors.colorSquidInk,
  TextSecondary: meridianColors.colorGray500,
  TextSuccess: '#1e8900',
  TextError: '#cf1943',
  TextInverted: meridianColors.colorGray0,
  TextWarning: '#ec7411',
  TextQueued: '#00bfff',
  TextInProgress: '#0073bb',
};

export default excelsior;
