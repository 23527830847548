import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import {ForecastRecordWithValues} from '../models/getForecastResponse';
import {handleError} from './handleError';

export interface GetForecastParams {
  businessId: string;
  country: string;
  forecastId: string;
  versionId: number;
  siteIdentifiers: string[] | undefined;
  metrics: string[] | undefined;
}

export const getForecastEndpoint = async (
  forecastingApiGatewayClient: ApiGatewayClient,
  params: GetForecastParams
): Promise<ForecastRecordWithValues | void> => {
  try {
    const {businessId, country, forecastId, versionId, siteIdentifiers, metrics} = params;
    const queryParams = { versionId: versionId.toString() } as { [key: string]: string };
    if (siteIdentifiers && siteIdentifiers.length) {
      queryParams.siteIdentifiers = siteIdentifiers.join(',');
    }
    if (metrics && metrics.length) {
      queryParams.metrics = metrics.join(',');
    }
    return (
      await forecastingApiGatewayClient.invokeApi(
        {businessId, country, forecastId},
        '/business/{businessId}/country/{country}/forecast/{forecastId}',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGatewayErrorResponse);
  }
};
