import {ForecastRecord} from '../apis/models/forecast';
import {ERROR, IN_PROGRESS, SUCCESS} from '../constants/forecastStatus';
import {BASELINE, CLONED, DISCARDED, FINAL} from '../constants/forecastType';
import {formatPeriodDate} from './dates';

export const normalizeParameter = (value: string | undefined | null): string | undefined => {
  return !value || value === 'undefined' || value === 'null' ? undefined : value;
};

export const formatFriendlyId = (forecast: ForecastRecord): string => {
  return forecast.forecastId.substring(0, 8);
};

export const formatForecastName = (forecast: ForecastRecord): string => {
  return `${forecast.country} ${forecast.businessId} ${formatPeriodDate(forecast.period, forecast.periodStart)}`;
};

export const formatForecastStatus = (status: string, type: string): string => {
  if (type === DISCARDED) { return 'Discarded'; }
  switch (status) {
    case IN_PROGRESS:
      return 'Processing';
    case ERROR:
      return 'Error';
    case SUCCESS:
      switch (type) {
        case FINAL:
          return 'Final';
        case BASELINE:
        case CLONED:
        default:
          return 'Open';
      };
    default:
      return 'Unknown';
  };
};
