import React, {useCallback, useState} from 'react';
import ActionPopover from '../../common/components/ActionPopover';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import {postRegenerateForecast} from '../../common/apis/forecasting-api-client';
import {IActionButtonProps} from './models';
import ForecastTitle from './ForecastTitle';

const RegenerateForecastLinkButton = ({anchorRef, forecast, open, setOpen}: IActionButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const actionCallback = useCallback(() => {
    if (isLoading) { return Promise.resolve(); }
    setIsLoading(true);
    return postRegenerateForecast({
      businessId: forecast.businessId,
      country: forecast.country,
      forecastId: forecast.forecastId,
      versionId: forecast.versionId,
    })
    .catch((err) => {
      console.log('ERROR REGENERATING RECORD', err);
      setIsLoading(false);
    })
    .then(() => {
      setIsLoading(false);
      window.location.href = '/';
    });
  }, [isLoading, forecast.businessId, forecast.country, forecast.forecastId, forecast.versionId]);

  return (
    <ActionPopover
      actionButtonText="Yes"
      actionCallback={actionCallback}
      actionIsLoading={isLoading}
      actionTitle="Regenerate Forecast"
      anchorRef={anchorRef}
      cancelButtonText="No"
      disabled={isLoading}
      open={open}
      setOpen={setOpen}
    >
      <ForecastTitle forecast={forecast} />
      <Row>
        <Text>Are you sure that you want to regenerate this forecast? Are you done updating the inputs?</Text>
      </Row>
    </ActionPopover>
  );
};

export default RegenerateForecastLinkButton;
