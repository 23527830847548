import React, {useEffect, useState} from 'react';
import {useAppSelector, useAuthContext} from './hooks';
import {selectCachedBusiness, selectCachedCountry} from './slices/cachedStateSlice';
import {selectForecastVersionIdentifier} from './slices/forecastSlice';
import {getForecast} from '../common/apis/forecasting-api-client';
import {ForecastRecordWithValues} from '../common/apis/models/getForecastResponse';
import {IN_PROGRESS, SUCCESS} from '../common/constants/forecastStatus';
import {CLONED, FINAL} from '../common/constants/forecastType';

export interface ForecastState {
  canApprove: boolean;
  canClone: boolean;
  canDiscard: boolean;
  canDownload: boolean;
  canOverlay: boolean;
  canRegenerate: boolean;
  ownedByCurrentUser: boolean;
  unprocessedOverlayMetrics: string[];
};

export interface ForecastContextType {
  forecast: ForecastRecordWithValues | null;
  forecastState: ForecastState;
  forecastId: string | null;
  versionId: number | null;
  businessId: string | null;
  country: string | null;
};

const defaultState: ForecastContextType = {
  forecast: null,
  forecastState: {
    canApprove: false,
    canClone: false,
    canDiscard: false,
    canDownload: false,
    canOverlay: false,
    canRegenerate: false,
    ownedByCurrentUser: false,
    unprocessedOverlayMetrics: [],
  },
  forecastId: null,
  versionId: null,
  businessId: null,
  country: null,
};

const getUnprocessedOverlayMetrics = (forecast: ForecastRecordWithValues | null | void): string[] => {
  if (!forecast) { return []; }

  // We only care if we are looking at the most recent forecast version
  if (forecast.versions?.forecast[0]?.versionId !== forecast.versionId) {
    return [];
  }

  // Check overlays for all metrics and look for version numbers greater than the current forecast version
  return Object.entries(forecast.versions?.overlay)
    .filter(([metric, versions]) => versions[0]?.versionId > forecast.versionId)
    .map(([metric]) => metric);
};

export const determineForecastState = (
  forecast: ForecastRecordWithValues | null | void,
  currentUser: string,
  isAdministrator: boolean,
  isApprover: boolean
): ForecastState => {
  const forecastState = {...defaultState.forecastState};

  if (forecast) {
    const unprocessedOverlayMetrics = getUnprocessedOverlayMetrics(forecast);
    forecastState.ownedByCurrentUser = currentUser.toLowerCase().startsWith(forecast.updatedBy.toLowerCase());
    const canWriteForecast = (forecastState.ownedByCurrentUser || isAdministrator || isApprover);

    forecastState.canDownload = Boolean(forecast.status === SUCCESS);
    forecastState.canApprove = Boolean(isApprover && forecastState.canDownload && forecast.type !== FINAL);
    forecastState.canOverlay = Boolean(canWriteForecast && forecastState.canDownload && forecast.type !== FINAL);
    forecastState.canClone = Boolean(forecastState.canDownload && forecast.type !== FINAL);
    forecastState.canDiscard = Boolean(forecast.status !== IN_PROGRESS && forecast.type === CLONED && canWriteForecast);
    forecastState.canRegenerate = Boolean(canWriteForecast && unprocessedOverlayMetrics.length);
    forecastState.unprocessedOverlayMetrics = unprocessedOverlayMetrics;
  }

  return forecastState;
};

export const ForecastContext = React.createContext<ForecastContextType>(defaultState);

const ForecastProvider = ({children}: React.PropsWithChildren<Record<never, any>>) => {
  const businessId = useAppSelector(selectCachedBusiness) as string;
  const country = useAppSelector(selectCachedCountry) as string;
  const {user: currentUser, isAdministrator, isApprover} = useAuthContext();
  const {forecastId, versionId} = useAppSelector(selectForecastVersionIdentifier);
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    const loadForecast = async () => {
      if (!businessId || !country || !forecastId || !versionId) {
        return;
      }
      const forecast = await getForecast({
        businessId,
        country,
        forecastId,
        versionId,
        siteIdentifiers: undefined,
        metrics: undefined,
      });
      setState({
        forecast: forecast || null,
        forecastState: determineForecastState(forecast, currentUser, isAdministrator, isApprover),
        forecastId: forecast?.forecastId || null,
        versionId: forecast?.versionId || null,
        businessId: forecast?.businessId || null,
        country: forecast?.country || null,
      });
    };
    loadForecast();
  }, [businessId, country, currentUser, forecastId, isAdministrator, isApprover, versionId]);

  return (
    <ForecastContext.Provider value={state}>
      {children}
    </ForecastContext.Provider>
  );
};

export default ForecastProvider;
