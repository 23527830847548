import React from 'react';
import {NavLink} from 'react-router-dom';
import {ITheme} from '../../../common/styles/themes/models';

import { getLinkNotSelected, getLinkSelected } from './LinkStyles';

interface IProps {
  theme: ITheme;
};

const HelpLink = ({theme}: IProps) => {
  return (
    <NavLink exact to="/help" className={getLinkNotSelected(theme)} activeClassName={getLinkSelected(theme)}>
      Help
    </NavLink>
  );
};

export default HelpLink;
