import React from 'react';
import Loader from '@amzn/meridian/loader';
import Text from '@amzn/meridian/text';
import {css} from 'emotion';

const loadingSpacing = css`{
  width: 200px;
  height: 200px;
  margin: 0 auto;
  padding: 10px;
  padding-top: 140px;
  position: relative;
`;

const ChartLoading = () => {
  return (
    <div className={loadingSpacing}>
      <Loader type="linear" size="large" />
      <Text type="h400">Loading</Text>
    </div>
  );
};

export default ChartLoading;
