import {encode} from 'base64-arraybuffer';
import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import {PostForecastOverlayBulkEditResponse} from '../models/postForecastOverlayBulkEditResponse';
import {extractStatusCode, handleError} from './handleError';

export interface PostForecastOverlayBulkEditParams {
  businessId: string;
  country: string;
  forecastId: string;
  overlayMetric: string;
  file: File;
};

export const postForecastOverlayBulkEditEndpoint = async (
  forecastingApiGatewayClient: ApiGatewayClient,
  params: PostForecastOverlayBulkEditParams
): Promise<PostForecastOverlayBulkEditResponse | void> => {
  try {
    const {businessId, country, forecastId, overlayMetric, file} = params;
    const fileContents = await file.arrayBuffer();
    return (
      await forecastingApiGatewayClient.invokeApi(
        {businessId, country, forecastId, overlayMetric},
        '/business/{businessId}/country/{country}/forecast/{forecastId}/overlay/{overlayMetric}',
        'POST',
        {
          headers: {
            // Force Content-Type to text/csv for csv extensions. Some browsers will send Excel type for .csv files.
            'Content-Type': file.name.toLowerCase().endsWith('.csv') ? 'text/csv' : file.type,
          },
        },
        // Note: sending the contents as a base-64 encoded string bypasses a bug in the API Gateway client where the client-generated
        //       body hash will not match the server-generated body hash if a buffer is sent.
        encode(fileContents),
      )
    ).data;
  } catch (error) {
    const apiError = error as ApiGatewayErrorResponse;
    if (extractStatusCode(apiError) === 400) {
      throw new Error(apiError.response.data?.message || apiError.response.statusText)
    }
    handleError(error as ApiGatewayErrorResponse);
  }
};
