import React, {useCallback} from 'react';
import {css} from 'emotion';
import Popover, {PopoverHeader} from '@amzn/meridian/popover';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';

interface IProps {
  actionButtonText: string;
  actionCallback: () => Promise<void>;
  actionIsLoading?: boolean;
  actionTitle: string;
  anchorRef: React.RefObject<any>;
  cancelButtonText?: string;
  disabled?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  showCancelButton?: boolean;
};

const loaderStyle = css`
  padding-top: 3px;
`

const ActionPopover = ({
  actionButtonText,
  actionCallback,
  actionIsLoading,
  actionTitle,
  anchorRef,
  cancelButtonText,
  children,
  disabled,
  open,
  setOpen,
  showCancelButton,
}: React.PropsWithChildren<IProps>) => {
  const onActionClicked = useCallback(() => {
    actionCallback().then(() => { setOpen(false); })
  }, [actionCallback, setOpen]);
  const onCloseClicked = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  return (
    <Popover
      anchorNode={anchorRef.current}
      open={open}
      onClose={onCloseClicked}
      position="right"
      alignment="center"
    >
      <PopoverHeader closeLabel="Close">
        <Row>
          <Text type="h300">{actionTitle}</Text>
          {actionIsLoading ? <span className={loaderStyle}><Loader size="small" /></span> : null}
        </Row>
      </PopoverHeader>
      <Column>
        <Divider size="small" />
        {children}
        <Divider size="small" />
        <Row alignmentHorizontal="center">
          {
            (showCancelButton !== false) ?
              <Button type="secondary" onClick={onCloseClicked}>{cancelButtonText || 'Cancel'}</Button> : null
          }
          <Button disabled={Boolean(disabled)} type="tertiary" onClick={onActionClicked}>{actionButtonText}</Button>
        </Row>
      </Column>
    </Popover>
  )
};

export default ActionPopover;
