import React, {useCallback, useState} from 'react';
import {useDropzone, FileRejection} from 'react-dropzone';
import {css} from 'emotion';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Upload from '@amzn/meridian-tokens/base/icon/upload-small';
import Toaster from '@amzn/meridian/toaster';
import Alert from '@amzn/meridian/alert';
import {postForecastOverlayBulkEdit} from '../../../common/apis/forecasting-api-client';

interface IToastStatus {
  status: string;
  fileName: string;
  message: string;
}

interface IState {
  toasts: {
    id: string;
    timeout: number;
    data: IToastStatus | undefined;
  }[];
}

interface IProps {
  businessId: string;
  country: string;
  forecastId: string;
  onUploadSuccess: () => void;
  overlayMetric: string;
  shouldEnable: boolean;
}

const uploadButtonStyle = css`
  padding-left: 0;
`;

const uploadButtonTextStyle = css`
  margin-left: 6px !important;
`;

let toastId = 0;
const toastLimit = 2;

const UploadTemplateButton = ({businessId, country, forecastId, onUploadSuccess, overlayMetric, shouldEnable}: IProps) => {
  const [toasts, setToasts] = useState<IState['toasts']>([]);

  const onCloseToast = useCallback((id: string) => setToasts(toasts.filter((t) => t.id !== id)), [toasts]);
  const onOpenToast = useCallback(
    (status: string, fileName: string, message: string) => {
      const toastStatus = {
        status: status,
        fileName: fileName,
        message: message,
      } as IToastStatus;

      setToasts(
        toasts.concat({
          id: `${++toastId}`,
          timeout: 5000,
          data: toastStatus,
        })
      );
    },
    [toasts]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      acceptedFiles.forEach(async (file: File) => {
        try {
          await postForecastOverlayBulkEdit({
            businessId,
            country,
            forecastId,
            overlayMetric,
            file
          });
          onUploadSuccess();
        } catch (e: any) {
          const message = e?.message || 'Check the browser console for the failure reason.';
          onOpenToast('error', file.name, 'File upload failed: ' + message);
        }
      });
      fileRejections.forEach(async (fileRejection: FileRejection) => {
        onOpenToast('error', fileRejection.file.name, 'File format is not supported. Only Excel files (.xls, .xlsx, .csv) are supported.');
      });
    },
    [businessId, country, forecastId, onOpenToast, onUploadSuccess, overlayMetric]
  );
  const {getRootProps, getInputProps} = useDropzone({
    onDrop: onDrop,
    multiple: false,
    accept: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
    ]
  });

  return (
    <>
      <Toaster toasts={toasts} onCloseToast={onCloseToast} alignmentHorizontal="center">
        {(toast) => (
          <Alert toast={true} onClose={toast.onClose} type={toast.data.status} size="large">
            <strong>{toast.data.fileName}</strong> {toast.data.message}
          </Alert>
        )}
      </Toaster>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button className={uploadButtonStyle} type="tertiary" size="medium" minWidth="210px" disabled={!shouldEnable || toasts.length >= toastLimit}>
          <Icon tokens={Upload} />
          <span className={uploadButtonTextStyle}>Upload Data</span>
        </Button>
      </div>
    </>
  );
};

export default UploadTemplateButton;
