import React from 'react';
import {css} from 'emotion';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import Toggle from '@amzn/meridian/toggle';
import {ForecastState} from '../../../app/ForecastProvider';
import SiteFilter, {SiteData} from '../../../common/components/SiteFilter';
import UploadTemplateButton from './UploadTemplateButton';

const rowSpacing = css`
  padding-top: 0.5%;
  padding-bottom: 0.5%;
`;

const smallMargin = css`
  margin-bottom: 4px !important;
`;

interface IProps {
  businessId: string;
  country: string;
  filterValue: string[];
  forecastId: string;
  forecastState: ForecastState;
  hasNewerOverlays: boolean;
  isNetworkLevel: boolean;
  isWidescreen: boolean;
  onOverlayUploadSuccess: () => void;
  overlayMetric: string;
  setFilter: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  setUseForecastOverlayVersionId: React.Dispatch<React.SetStateAction<boolean>>;
  siteData: SiteData;
  useForecastOverlayVersionId: boolean;
};

const OverlayHeader = ({
  businessId,
  country,
  filterValue,
  forecastId,
  forecastState,
  hasNewerOverlays,
  isNetworkLevel,
  isWidescreen,
  onOverlayUploadSuccess,
  overlayMetric,
  setFilter,
  setUseForecastOverlayVersionId,
  siteData,
  useForecastOverlayVersionId,
}: IProps) => {
  const {canOverlay} = forecastState;
  const downloadButton = (
    <Button type="tertiary">Download Template / Data</Button>
  );
  const uploadButton = (
    <UploadTemplateButton
      businessId={businessId}
      country={country}
      forecastId={forecastId}
      onUploadSuccess={onOverlayUploadSuccess}
      overlayMetric={overlayMetric}
      shouldEnable={true}
    />
  );
  const versionToggle = (
    <Toggle checked={useForecastOverlayVersionId} onChange={setUseForecastOverlayVersionId}>
      View values used in forecast
    </Toggle>
  );

  return (
    <>
      {
        isNetworkLevel ? null : (
          <SiteFilter
            isWidescreen={isWidescreen}
            filterValue={filterValue}
            setFilter={setFilter}
            siteData={siteData}
          />
        )
      }
      <div className={rowSpacing}>
        {
          isWidescreen || !hasNewerOverlays ? (
            <Row className={smallMargin}>
              {downloadButton}
              {canOverlay ? uploadButton : null}
              {hasNewerOverlays ? versionToggle : null}
            </Row>
          ) : (
            <>
              <Row className={smallMargin}>
                {downloadButton}
                {canOverlay ? uploadButton : null}
              </Row>
              <Row className={smallMargin}>
                {versionToggle}
              </Row>
            </>
          )
        }
      </div>
    </>
  );
};

export default OverlayHeader;
