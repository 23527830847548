import React from 'react';
import {formatValue} from './formatting';

interface CustomLabelProps {
  x?: number;
  y?: number;
  stroke?: string;
  value?: string;
};

const DataValueLabel = ({x, y, stroke, value}: CustomLabelProps) => {
  return (
    /* @ts-ignore */
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {formatValue(value)}
    { /* @ts-ignore */ }
    </text>
  );
};

export default DataValueLabel;
