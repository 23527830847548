import React from 'react';
import {css} from 'emotion';
import DateRangePicker from '@amzn/meridian/date-range-picker';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import DebouncedSearch from '../../../common/components/DebouncedSearch';
import {DEFAULT_SEARCH_SIZE} from '../../../common/components/DebouncedSearch';

const rowSpacing = css`
  padding-bottom: 0.5%;
`;

const smallMargin = css`
  margin-bottom: 4px !important;
`;

interface IProps {
  isWidescreen: boolean;
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  startDate: string;
  setStartDateValue: React.Dispatch<React.SetStateAction<string>>;
  endDate: string;
  setEndDateValue: React.Dispatch<React.SetStateAction<string>>;
}

const ForecastListHeader = (
    {
      isWidescreen,
      searchInput,
      setSearchInput,
      startDate,
      setStartDateValue,
      endDate,
      setEndDateValue,
    }: IProps) => {
  const searchColumnProps = {minWidth: DEFAULT_SEARCH_SIZE};
  const dateColumnProps = {minWidth: 250};
  const setDateRange = (value: string[] | undefined) => {
    setStartDateValue((value && value[0]) || '');
    setEndDateValue((value && value[1]) || '');
  };

  const textSearch = (
    <DebouncedSearch
      width={500}
      searchFieldSize={'xlarge'}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
    />);

  const datePicker = (
    <DateRangePicker
      width={500}
      value={[startDate, endDate]}
      onChange={setDateRange}
      startLabel="Start Date"
      endLabel="End Date"
      monthsInView={2}
    />);

  return isWidescreen ? (
    <Row className={rowSpacing}>
      <Column {...searchColumnProps}>
        {textSearch}
      </Column>
      <Column {...dateColumnProps}>
        {datePicker}
      </Column>
    </Row>
  ): (
    <>
      <Row className={smallMargin}>
        {textSearch}
      </Row>
      <Row className={smallMargin}>
        {datePicker}
      </Row>
    </>
  );
};

export default ForecastListHeader;
