import React, { useState } from 'react';
import escapeRegExp from 'lodash.escaperegexp';
import Select, { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';

export type TypeaheadSelectOption = {
  label: string;
  value: string;
}

interface IProps {
  setValue: (value: string | string[] | undefined) => void;
  value: string | string[] | undefined;
  label: string;
  labelFormatter?: ((values: any[]) => string);
  options: TypeaheadSelectOption[];
  placeholder: string;
  width?: number | string;
};

const TypeaheadSelect = ({options, label, labelFormatter, placeholder, setValue, value, width}: IProps) => {
  const [searchQuery, setSearchQuery] = useState();

  // Here we filter out options by creating an escaped regular expression
  // out of the search query and test it against each option label
  const searchRegExp = new RegExp(escapeRegExp(searchQuery), 'i');
  const matchedOptions = options.filter(
    option => !searchQuery || searchRegExp.test(option.label)
  );

  return (
    <Select
      value={value}
      onChange={setValue}
      searchQuery={searchQuery}
      onSearch={setSearchQuery}
      placeholder={placeholder}
      width={width}
      label=""
      valueLabel={labelFormatter}
      prefix={label}
    >
      {matchedOptions.map(option => (
        // We take all matched options and pass them as SelectOptions
        <SelectOption
          label={option.label}
          value={option.value}
          key={option.value}
        />
      ))}
      {!matchedOptions.length ? (
        // If there are no matched options, we display a message to our
        // users saying their query returned no results
        <Column alignmentVertical="center" spacing="300" spacingInset="600">
          <Text alignment="center">No results</Text>
        </Column>
      ) : null}
    </Select>
  );
};

export default TypeaheadSelect;
