import React from 'react';

interface CustomAxisProps {
  x?: number;
  y?: number;
  stroke?: string;
  payload?: any;
};

const DateAxis = ({x, y, payload}: CustomAxisProps) => {
  return (
    /* @ts-ignore */
    <g transform={`translate(${x},${y})`}>
      { /* @ts-ignore */ }
      <text x={0} y={0} dy={15} textAnchor="end" fill="#666" transform="rotate(-45)">
        {payload.value}
      { /* @ts-ignore */ }
      </text>
    { /* @ts-ignore */ }
    </g>
  );
};

export default DateAxis;
