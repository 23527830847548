import React, {useCallback} from 'react';
import ActionPopover from '../../common/components/ActionPopover';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import {IActionButtonProps} from './models';
import ForecastTitle from './ForecastTitle';

const DownloadForecastLinkButton = ({anchorRef, forecast, open, setOpen}: IActionButtonProps) => {
  const actionCallback = useCallback(() => {
    console.log(`Download Forecast: ${forecast.businessId}:${forecast.country}:${forecast.forecastId}:${forecast.versionId}`);
    return Promise.resolve();
  }, [forecast.businessId, forecast.country, forecast.forecastId, forecast.versionId]);

  return (
    <ActionPopover
      actionButtonText="Yes"
      actionCallback={actionCallback}
      actionTitle="Download Forecast to CSV"
      anchorRef={anchorRef}
      cancelButtonText="No"
      open={open}
      setOpen={setOpen}
    >
      <ForecastTitle forecast={forecast} />
      <Row>
        <Text>Are you sure that you want to download this forecast?</Text>
      </Row>
    </ActionPopover>
  );
};

export default DownloadForecastLinkButton;
