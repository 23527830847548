import omit from 'lodash.omit';
import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import {filterNullEmptyOrUndefinedKeys} from '../../utils/object';
import {GetForecastListResponse} from '../models/getForecastListResponse';
import {handleError} from './handleError';

export interface GetForecastListParams {
  businessId: string;
  country: string;
  query: string | undefined;
  startTime: Date | undefined;
  endTime: Date | undefined;
  offset: number | undefined;
  pageSize: number | undefined;
  sortKey: string | undefined;
  sortDirection: string | undefined;
}

export const getForecastListEndpoint = async (
  forecastingApiGatewayClient: ApiGatewayClient,
  params: GetForecastListParams
): Promise<GetForecastListResponse | void> => {
  try {
    const {businessId, country, startTime, endTime} = params;
    const queryParams = filterNullEmptyOrUndefinedKeys({
      ...omit(params, ['businessId', 'country', 'startTime', 'endTime']),
      startTime: (startTime && Math.floor(startTime.getTime() / 1000)) || '',
      endTime: (endTime && Math.ceil(endTime.getTime() / 1000)) || '',
    });
    if (queryParams.sortKey === 'forecastDetail') { queryParams.sortKey = 'forecastId'; }
    if (queryParams.sortKey === 'forecastName') { queryParams.sortKey = 'period'; }
    return (
      await forecastingApiGatewayClient.invokeApi(
        {businessId, country},
        '/business/{businessId}/country/{country}/forecast',
        'GET',
        {queryParams},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGatewayErrorResponse);
  }
};
