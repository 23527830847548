import {ApiGatewayClient, ApiGatewayErrorResponse} from '../apiGatewayClient';
import {ForecastGraph} from '../models/getForecastGraphResponse';
import {handleError} from './handleError';

export interface GetForecastGraphParams {
  businessId: string;
  country: string;
  forecastId: string;
}

export const getForecastGraphEndpoint = async (
  forecastingApiGatewayClient: ApiGatewayClient,
  params: GetForecastGraphParams
): Promise<ForecastGraph | void> => {
  try {
    const {businessId, country, forecastId} = params;
    return (
      await forecastingApiGatewayClient.invokeApi(
        {businessId, country, forecastId},
        '/business/{businessId}/country/{country}/forecast/{forecastId}/graph',
        'GET',
        {},
        null
      )
    ).data;
  } catch (error) {
    handleError(error as ApiGatewayErrorResponse);
  }
};
