import React, {useEffect, useRef, useState} from 'react';
import D3Tree from 'react-d3-tree';
import {CustomNodeElementProps, TreeLinkDatum} from 'react-d3-tree/lib/types/common';
import {css} from 'emotion';
import Row from '@amzn/meridian/row';
import {useAuthContext} from '../../../app/hooks';
import {ForecastGraph} from '../../../common/apis/models/getForecastGraphResponse';
import PageLoading from '../../../common/components/PageLoading';
import {ITheme} from '../../../common/styles/themes/models';
import {formatGraphData, NodeAttributes} from './utils';

const d3ContainerStyle = css`
  height: 100%;
`;

interface IProps {
  forecastGraph: ForecastGraph | null;
  forecastId: string | null;
  versionId: number | null;
  isLoading: boolean;
  onRestoreDiscardedForecast: (forecastData: NodeAttributes) => void;
  onSelectForecast: (orecastData: NodeAttributes) => void;
  theme: ITheme;
};

const ForecastD3Graph = (props: IProps) => {
  const {forecastId, forecastGraph, isLoading, theme, versionId} = props;
  const {user: currentUser, isAdministrator, isApprover} = useAuthContext();
  const containerRef = useRef<HTMLElement>();
  const [treePosition, setTreePosition] = useState({x: 0, y: 0});
  const [treeDimensions, setTreeDimensions] = useState({width: 0, height: 0});

  useEffect(() => {
    if (containerRef.current) {
      const {width, height} = containerRef.current.getBoundingClientRect();
      setTreeDimensions({width, height})
      setTreePosition({x: width / 2, y: 20});
    }
  }, [containerRef])

  const treeData = !!forecastGraph ? formatGraphData({
    forecastId: forecastId!,
    versionId: versionId!,
    forecastGraph,
    currentUser,
    isAdministrator,
    isApprover,
  }) : undefined;

  return (
    <Row className={d3ContainerStyle} ref={containerRef}>
    {
      isLoading ?
        <PageLoading /> :
        <D3Tree
          data={treeData}
          collapsible={false}
          orientation="vertical"
          separation={{siblings: 3, nonSiblings: 1}}
          dimensions={treeDimensions}
          translate={treePosition}
          pathClassFunc={getLinkStyle.bind(this, theme)}
          renderCustomNodeElement={renderRectSvgNode.bind(this, props)}
          scaleExtent={{min: 0.5, max: 1}}
          zoom={0.8}
        />
    }
    </Row>
  );
};

const getLinkStyle = (theme: ITheme, link: TreeLinkDatum): string => {
  const isFamily = Boolean(link.target.data.attributes?.isAncestor || link.target.data.attributes?.isSelected);
  const color = isFamily ? theme.TextInProgress : theme.TextSecondary;
  return css`
    stroke: ${color} !important;
    stroke-width: ${isFamily ? 2 : 1}px !important;
  `;
};

const NODE_DISPLAY_ATTRIBUTES = [
  ['', 'description'],
  ['Status', 'status'],
  ['Version', 'versionId'],
  ['Author', 'author'],
  ['Updated', 'updated']
];
const renderRectSvgNode = (
  {theme, onRestoreDiscardedForecast, onSelectForecast}: IProps,
  {nodeDatum}: CustomNodeElementProps,
) => {
  const attributes = nodeDatum.attributes as any as NodeAttributes;
  const attributeMap = attributes as any as {[key: string]: string};
  const unableToView = attributes.isDiscarded && !attributes.currentUserCanRestore;
  const isRestorable = attributes.isDiscarded && attributes.currentUserCanRestore;
  const nodeProps = {
    cx: 0,
    cy: 10,
    r: 10,
    fill: theme.ChartAggregateSeries,
    strokeWidth: 1,
    pointerEvents: 'none',
  }
  const selectedNodeStyleProps = {
    ...nodeProps,
    stroke: theme.TextInProgress,
    fill: theme.TextQueued,
    strokeWidth: 3,
  };
  const discardedNodeStyleProps = {
    ...nodeProps,
    fill: theme.TextError,
  };
  const ancestorNodeStyleProps = {
    ...nodeProps,
    fill: theme.RowHeaderBackground,
  };
  const textStyleProps = {
    fontFamily: '\'Amazon Ember\', Arial, sans-serif',
    strokeWidth: 0,
    pointerEvents: 'none',
  };
  const textLabelStyleProps = {
    ...textStyleProps,
    fill: unableToView ? theme.TextSecondary :
      attributes.isSelected ? theme.TextInProgress :
      theme.TextPrimary,
    fontStyle: attributes.isDiscarded ? 'italic' : 'normal',
    fontWeight: 800,
    x: 20,
  };
  const idLabelStyleProps = {
    ...textLabelStyleProps,
    textDecoration: unableToView ? 'none' : 'underline',
    pointerEvents: unableToView ? 'none' : 'all',
    onClick: isRestorable ?
      () => { onRestoreDiscardedForecast(attributes); } :
      attributes.isDiscarded ? () => { /* no-op */ } :
      () => { onSelectForecast(attributes); },
  };
  const textValueStyleProps = {
    ...textStyleProps,
    fill: attributes.isDiscarded ? theme.TextSecondary : theme.TextPrimary,
    fontStyle: attributes.isDiscarded ? 'italic' : 'normal',
    x: 96,
  };
  const currentNodeStyleProps =
    attributes.isSelected ? selectedNodeStyleProps :
    attributes.isDiscarded ? discardedNodeStyleProps :
    attributes.isAncestor ? ancestorNodeStyleProps :
    nodeProps;

  const lineHeight = 20;
  return (
    <>
      <g>
        <circle {...currentNodeStyleProps} />
        <text {...idLabelStyleProps}>
          {nodeDatum.name}
          {isRestorable ? ' (restore)' : null}
        </text>
        {
          NODE_DISPLAY_ATTRIBUTES.map(([label, key], ix) => (
            <>
              <text {...textLabelStyleProps} dy={lineHeight * (ix + 1)}>
                {label ? `${label}:` : attributeMap[key]}
              </text>
              { label ? (<text {...textValueStyleProps} dy={lineHeight * (ix + 1)}>
                  {attributeMap[key]}
                </text>) : undefined}
            </>
          ))
        }
      </g>
    </>
  );
};

export default ForecastD3Graph;
