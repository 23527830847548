import React from 'react';
import Select, {SelectOption} from '@amzn/meridian/select';

import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {selectCountrySelector, setCountry} from '../../../app/slices/businessSlice';

const CountrySelector = () => {
  const {countrySelection, countryVisible, countryValidChoices} = useAppSelector(selectCountrySelector);
  const dispatch = useAppDispatch();

  const handleChange = (country: string) => {
    dispatch(setCountry(country));
  };

  return (
    <>
      {countryVisible ? (
        <Select value={countrySelection} onChange={handleChange} width={300} label="Country" popoverMaxHeight={250}>
          {countryValidChoices.map((choice: string, ix: number) => (
            <SelectOption label={choice} value={choice} key={`select-country-${ix}`} />
          ))}
        </Select>
      ) : null}
    </>
  );
};

export default CountrySelector;
