import React, {useEffect, useState} from 'react';
import {Auth, Logger as AmplifyLogger} from 'aws-amplify';
import {Credentials} from 'aws-sdk/clients/sts';
import config, {SystemAPIs} from '../common/config';
import {useAppSelector} from './hooks';
import {selectCachedBusiness, selectCachedCountry} from './slices/cachedStateSlice';

export interface BusinessHierarchyNode {
    name: string;
    value: string;
    nodes: BusinessHierarchyNode[];
    permissions?: string[];
}

export interface AuthTokenPayload {
    user: string;
    backendApiCredentials?: Credentials;
    isAdministrator: boolean;
    isApprover: boolean;
    permissionsSet: Set<string>;
    permissionsTree: BusinessHierarchyNode[];
}

interface Settings {
    stage: string;
    region: string;
    cognitoAppClientId: string;
    cognitoAuthDomain: string;
    cognitoUserPoolId: string;
    cognitoIdentityPoolId: string;
    backendApiEndpoint: string;
}

const defaultState = {
    isAdministrator: false,
    isApprover: false,
    isAuthenticated: false,
    user: '',
    permissionsSet: new Set<string>(),
    permissionsTree: [] as BusinessHierarchyNode[],
};

export const AuthContext = React.createContext(defaultState);

AmplifyLogger.LOG_LEVEL = 'WARN';

const amplifySettings = (settings: Settings) => {
    config.Amplify.Auth.region = settings.region;
    config.Amplify.Auth.userPoolId = settings.cognitoUserPoolId;
    config.Amplify.Auth.oauth.domain = settings.cognitoAuthDomain;
    config.Amplify.Auth.userPoolWebClientId = settings.cognitoAppClientId;
    config.Amplify.Auth.identityPoolId = settings.cognitoIdentityPoolId;

    config.Amplify.API.endpoints.forEach((entry) => {
        if (entry.name === SystemAPIs.ForecastingApi.toString()) {
            entry.endpoint = settings.backendApiEndpoint;
            entry.region = settings.region;
        }
    });
};

export const authTokenPayload = async (businessId: string | null, country: string | null): Promise<AuthTokenPayload> => {
    const currentSession = await Auth.currentSession();
    const payload = currentSession.getIdToken().payload;
    const permissionsTree = payload.permissionsTree ? (JSON.parse(payload.permissionsTree) as BusinessHierarchyNode[]) : [];
    const permissionsSet = extractPermissionSet(payload.email, permissionsTree, businessId, country);
    return {
        user: payload.email,
        backendApiCredentials: payload.backendCredentials ? (JSON.parse(payload.backendCredentials) as Credentials) : undefined,
        isAdministrator: permissionsSet.has('canViewAdmin'),
        isApprover: permissionsSet.has('canFinalizeForecast'),
        permissionsSet,
        permissionsTree,
    };
};

const extractPermissionSet = (user: string, permissionsTree: any, businessId: string | null, country: string | null): Set<string> => {
    if (!businessId || !country || !user) { return new Set(); }

    const businessPermissions = permissionsTree.find((node: BusinessHierarchyNode) => node.name === 'businessType' && node.value === businessId!.toLowerCase());
    if (!businessPermissions) { return new Set(); }

    const countryFilter = (node: BusinessHierarchyNode) => node.name === 'country' && node.value === country!.toUpperCase();
    const regionPermissions = businessPermissions.nodes.find(
        (node: BusinessHierarchyNode) => node.name === 'regionId' && node.nodes.find(countryFilter));
    if (!regionPermissions) { return new Set(); }

    const countryPermissions = regionPermissions.nodes.find(countryFilter);
    return new Set(countryPermissions?.permissions || []);
};

const AuthProvider = ({children}: React.PropsWithChildren<Record<never, any>>) => {
    const [state, setState] = useState(defaultState);
    const businessSelection = useAppSelector(selectCachedBusiness);
    const countrySelection = useAppSelector(selectCachedCountry);

    useEffect(() => {
        const authentication = async () => {
            const settings = (await (await fetch(`${window.location.origin}/settings.json`)).json()) as Settings;
            amplifySettings(settings);
            Auth.configure(config.Amplify);

            try {
                await Auth.currentAuthenticatedUser();
            } catch (error) {
                console.error(error);
                setState(defaultState);
                await Auth.signOut();
                await Auth.federatedSignIn({customProvider: 'Midway'});
            }

            const tokenPayload = await authTokenPayload(businessSelection, countrySelection);
            setState({
                isAuthenticated: true,
                isAdministrator: tokenPayload.isAdministrator,
                isApprover: tokenPayload.isApprover,
                user: tokenPayload.user,
                permissionsSet: tokenPayload.permissionsSet,
                permissionsTree: tokenPayload.permissionsTree,
            });
        };

        authentication();
    }, [businessSelection, countrySelection, state.isAuthenticated]);

    if (state.isAuthenticated) {
        return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
    }

    return null;
};

export default AuthProvider;
